import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing(4, 4, 8, 4),
    maxWidth: '550px',
    height: '440px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
