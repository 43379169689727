import { INavGroup } from '../Interfaces/dataDescriptiors';

enum ElementNames {
  Button = 'Button',
  TextWrapper = 'Text wrapper',
  Tile = 'Tile',
  TextInput = 'Text input',
  NumberInput = 'Number input',
  LiveSearch = 'Live search',
  ImageUploader = 'Image uploader',

  Select = 'Select',
  MultiSelect = 'Multi select',
  AutoComplete = 'Auto complete',
  AutoCompleteSelect = 'Auto complete select',
  AutoMultiCompleteSelect = 'Auto complete multi select',
  CheckBox = 'Check box',

  TimeInput = 'Time input',
  DateTimeInput = 'Date time input',
  PeriodRangeInput = 'Period range input',

  InputGroup = 'Input group',
  ModalDialog = 'Modal dialog',
  Form = 'Form',
  Table = 'Table',
  Tabs = 'Tabs',
  Spinner = 'Spinner',

  AuthModule = 'Auth module',
  Forms = 'Forms',
}

const inputsGroup: INavGroup = {
  name: 'Inputs group',
  elements: [
    { nodeId: ElementNames.Button, label: ElementNames.Button, isExist: true },
    { nodeId: ElementNames.TextWrapper, label: ElementNames.TextWrapper, isExist: true },
    { nodeId: ElementNames.TextInput, label: ElementNames.TextInput, isExist: true },
    { nodeId: ElementNames.NumberInput, label: ElementNames.NumberInput, isExist: false },
    { nodeId: ElementNames.DateTimeInput, label: ElementNames.DateTimeInput, isExist: false },
    { nodeId: ElementNames.LiveSearch, label: ElementNames.LiveSearch, isExist: true },
    { nodeId: ElementNames.ImageUploader, label: ElementNames.ImageUploader, isExist: false },
  ],
};

const selectGroup: INavGroup = {
  name: 'Select group',
  elements: [
    { nodeId: ElementNames.Select, label: ElementNames.Select, isExist: false },
    { nodeId: ElementNames.AutoComplete, label: ElementNames.AutoComplete, isExist: false },
    { nodeId: ElementNames.MultiSelect, label: ElementNames.MultiSelect, isExist: false },
    { nodeId: ElementNames.AutoCompleteSelect, label: ElementNames.AutoCompleteSelect, isExist: false },
    { nodeId: ElementNames.AutoMultiCompleteSelect, label: ElementNames.AutoMultiCompleteSelect, isExist: false },
    { nodeId: ElementNames.CheckBox, label: ElementNames.CheckBox, isExist: true },
  ],
};

const groupingGroup: INavGroup = {
  name: 'Grouping group',
  elements: [
    { nodeId: ElementNames.InputGroup, label: ElementNames.InputGroup, isExist: true },
    { nodeId: ElementNames.Tile, label: ElementNames.Tile, isExist: false },
    { nodeId: ElementNames.ModalDialog, label: ElementNames.ModalDialog, isExist: false },
    { nodeId: ElementNames.Forms, label: ElementNames.Forms, isExist: true },
    { nodeId: ElementNames.Table, label: ElementNames.Table, isExist: true },
    { nodeId: ElementNames.Tabs, label: ElementNames.Tabs, isExist: false },
    { nodeId: ElementNames.Spinner, label: ElementNames.Spinner, isExist: false },
  ],
};

const compositionGroup: INavGroup = {
  name: 'Solutions',
  elements: [{ nodeId: ElementNames.AuthModule, label: ElementNames.AuthModule, isExist: true }],
};

const groupedElements: INavGroup[] = [inputsGroup, selectGroup, groupingGroup, compositionGroup];

export { groupedElements, ElementNames };
