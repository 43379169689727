import React, { MouseEvent } from 'react';

import { IconButton } from 'Elements/Buttons';

import Tooltip from '@mui/material/Tooltip';

import useStyles from './styles';

interface IProps {
  onClick: () => void;
  tooltip: string;
  icon: JSX.Element;
}

const TableAction = (props: IProps) => {
  const { onClick, tooltip, icon } = props;

  const c = useStyles();

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();

    onClick();
  };

  return (
    <Tooltip title={tooltip}>
      <IconButton icon={icon} className={c.btn} onClick={handleClick} />
    </Tooltip>
  );
};

export default TableAction;
