import React from 'react';

import Battery20Icon from '@mui/icons-material/Battery20';
import BackupIcon from '@mui/icons-material/Backup';

import { PrimaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from '../../styles';

const ButtonsWithIcon = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <>
        <div className={c.exampleWrapper}>
          <PrimaryButton
            startIcon={<Battery20Icon className={'iconClassName'} />}
            size={ButtonSize.Sm}
            onClick={() => {}}
            caption={'Apply'}
          />
        </div>

        <div className={c.exampleWrapper}>
          <PrimaryButton
            endIcon={<Battery20Icon className={'iconClassName'} />}
            size={ButtonSize.Sm}
            onClick={() => {}}
            caption={'Apply'}
          />
        </div>

        <div className={c.exampleWrapper}>
          <PrimaryButton
            size={ButtonSize.Sm}
            startIcon={<Battery20Icon className={'iconClassName'} />}
            endIcon={<BackupIcon className={'iconClassName'} />}
            onClick={() => {}}
            caption={'Сохранить'}
          />
        </div>

        <div className={c.exampleWrapper}>
          <PrimaryButton
            size={ButtonSize.Sm}
            startIcon={<Battery20Icon className={'iconClassName'} />}
            endIcon={<BackupIcon className={'iconClassName'} />}
            onClick={() => {}}
            caption={'Сохранить'}
          />
        </div>

        <div className={c.exampleWrapper}>
          <PrimaryButton
            disabled={true}
            size={ButtonSize.Sm}
            startIcon={<Battery20Icon className={'iconClassName'} />}
            endIcon={<BackupIcon className={'iconClassName'} />}
            onClick={() => {}}
            caption={'Сохранить'}
          />
        </div>
      </>
    </div>
  );
};

export default ButtonsWithIcon;
