import InfoCard from './InfoCard';
import { DescriptionWithAction, TableView } from './components';

export interface ICardItem {
  text: string;
  value: string;
}

export default InfoCard;

export { DescriptionWithAction, TableView };
