import { makeAutoObservable } from 'mobx';

import { materialStyle } from 'themes/materialStyle';
import { iskraStyle } from 'themes/iskraStyle';
import { kendoStyle } from 'themes/kendoStyle';

import { FullScreenExampleParams } from '../Services/AppParams';
import { INavGroup } from 'Shared/Interfaces/dataDescriptiors';

import { groupedElements } from 'Shared/Constatnts/constants';

class AppStore {
  public fullScreenParam = new FullScreenExampleParams();

  public filterPattern = '';

  public filteredElemsTree: INavGroup[] = groupedElements;

  public currentScroll = 100;

  public currentNavName = '123';

  public codeStyle: number = 1;

  private elemsTree = groupedElements;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const themeCode = window.localStorage.getItem('themeName');
    if (!themeCode) return;

    const value = parseInt(themeCode, 10);
    if (!value) return;

    this.codeStyle = value;
  }

  public get nodeWhichContainsFilterPattern() {
    const nodeNames = this.filteredElemsTree
      .filter(item => item.elements.some(elem => elem.label.toLowerCase().includes(this.filterPattern)))
      .map(item => item.name);
    if (!nodeNames) return [''];

    return nodeNames;
  }

  public setNavigationFilterPattern(pattern: string) {
    this.filterPattern = pattern;
    this.doFilter();
  }

  public doFilter() {
    this.elemsTree = groupedElements;
    this.filteredElemsTree = this.elemsTree.map(item => item);

    this.filteredElemsTree = this.filteredElemsTree.filter(item => {
      const isHasSuchGroup = item.name.toLowerCase().includes(this.filterPattern.toLowerCase());
      const isHasSuchElems = item.elements.some(elem =>
        elem.label.toLowerCase().includes(this.filterPattern.toLowerCase())
      );

      item.elements = item.elements.filter(el => el.label.toLowerCase().includes(this.filterPattern.toLowerCase()));

      return isHasSuchGroup || isHasSuchElems;
    });
  }

  public setCurrentScroll(value: number) {
    this.currentScroll = value;
  }

  public setCurrentNavName(name: string) {
    this.currentNavName = name;
  }

  public setStyle(value: number) {
    this.codeStyle = value;

    window.localStorage.setItem('themeName', value.toString());
    window.localStorage.setItem('scrollPosition', this.currentScroll.toString());
    window.history.go();
  }
}

export default new AppStore();
