import { IPageResponseDto, ITableExampleRowDto } from '../Shared/Interfaces';

class TableDataApiService {
  private data = [
    { id: 1, name: 'First', param1: 1.12, param2: 1.13, param3: 1.14, param4: 1.15 },
    { id: 2, name: 'Second', param1: 2.12, param2: 2.13, param3: 2.14, param4: 2.15 },
    { id: 3, name: 'Third', param1: 3.12, param2: 3.13, param3: 3.14, param4: 3.15 },
    { id: 4, name: 'Fourth', param1: 4.12, param2: 4.13, param3: 4.14, param4: 4.15 },
    { id: 5, name: 'Fifth', param1: 5.12, param2: 5.13, param3: 5.14, param4: 5.15 },
    { id: 6, name: 'Sixth', param1: 6.12, param2: 6.13, param3: 6.14, param4: 6.15 },
    { id: 7, name: 'Seventh', param1: 7.12, param2: 7.13, param3: 7.14, param4: 7.15 },
    { id: 8, name: 'Eighth', param1: 8.12, param2: 8.13, param3: 8.14, param4: 8.15 },
    { id: 9, name: 'Ninth', param1: 9.12, param2: 9.13, param3: 9.14, param4: 9.15 },
    { id: 10, name: 'Tenth', param1: 10.12, param2: 10.13, param3: 10.14, param4: 10.15 },
    { id: 11, name: 'Eleventh', param1: 11.12, param2: 11.13, param3: 11.14, param4: 11.15 },
    { id: 12, name: 'Twelveth', param1: 12.12, param2: 12.13, param3: 12.14, param4: 12.15 },
  ];
  constructor() {
    this.getData = this.getData.bind(this);
  }

  public getData(
    pageNumber: number,
    pageSize: number,
    sortingColumnName: any,
    isAscending: boolean
  ): Promise<IPageResponseDto<ITableExampleRowDto> | null> {
    return new Promise(resolve => {
      setTimeout(() => resolve(this.getPageData(pageNumber, pageSize, sortingColumnName, isAscending)), 500);
    });
  }

  private getPageData(
    pageNumber: number,
    pageSize: number,
    sortingColumnName: keyof ITableExampleRowDto,
    isAscending: boolean
  ): IPageResponseDto<ITableExampleRowDto> | null {
    const sorted = this.data.sort((a: ITableExampleRowDto, b: ITableExampleRowDto) =>
      isAscending
        ? a[sortingColumnName] > b[sortingColumnName]
          ? 1
          : -1
        : a[sortingColumnName] > b[sortingColumnName]
        ? -1
        : 1
    );

    const start = pageNumber * pageSize;

    const batch = sorted.slice(start, start + pageSize > sorted.length ? sorted.length - 1 : start + pageSize);

    return { page: batch, totalCount: this.data.length };
  }
}

export default new TableDataApiService();
