import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  authForWrapper: {
    position: 'relative',
  },
  inputItem: {
    padding: '16px',
  },
  authTitle: {
    margin: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  authForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '15vw',
    overflow: 'auto',
    minWidth: '200px',
  },
  authFormActions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '48px',
    //paddingBottom: '8px',
  },
  loginButton: {
    width: '80%',
  },
}));

export default useStyles;
