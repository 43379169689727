import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { LiveSearchGroups } from './Components';

import useStyles from './styles';


export enum SearchExampleTypes {
  SmSearch = 'SM size search',
  MdSearch = 'MD size search',
  LgSearch = 'LG size search',
}

const LiveSearch = () => {
  const c = useStyles();

  const items = [SearchExampleTypes.SmSearch, SearchExampleTypes.MdSearch, SearchExampleTypes.LgSearch];

  return (
    <ExamplesWrapper
      render={param => <LiveSearchGroups type={param} />}
      title={'Живой поиск (Live search)'}
      description={
        'Реализация живого поиска обеспечивает отправку запроса с некоторой задержкой, длительност которой управляется через параметр. Результаты поиска отображаются в выпадающем списке'
      }
      examples={items}
    />
  );
};

export default LiveSearch;
