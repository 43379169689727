import { makeStyles, DefaultTheme } from '@mui/styles';

import { bgColors, borderColors, borderRadius, indents } from 'themes/materialTheme';

interface IProps {
  width: string;
  height: string;
}

const useStyles = makeStyles<DefaultTheme, IProps>(theme => ({
  exampleWrapper: {
    height: `fit-content`,
  },
  exampleWrapperExpanded: {
    paddingLeft: theme.spacing(indents.sixteen),
    paddingRight: theme.spacing(indents.sixteen),
    position: 'absolute',
    top: 0,
    left: 0,
    width: `calc(100% - ${theme.spacing(indents.sixteen * 2)})`,
    height: `100vh`,
    zIndex: 2,
    backgroundColor: 'white',
  },
  exampleContent: {
    height: 'calc(100% - 40px)',
  },
  titleRow: {
    position: 'relative',
    width: '100%',
  },
  exampleTitle: {
    marginTop: theme.spacing(indents.thirtyTwo),
    marginBottom: theme.spacing(indents.sixteen),
  },
  exampleDescription: {
    marginTop: theme.spacing(indents.sixteen),
  },

  exampleDisplay: props => ({
    padding: indents.sixteen,
    backgroundColor: bgColors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    minWidth: props.width ? `${props.width}px` : 'fit-content',
    maxWidth: props.width ? `${props.width}px` : 'fit-content',
    minHeight: props.height ? `${props.height}px` : 'fit-content',
    maxHeight: props.height ? `${props.height}px` : 'fit-content',
  }),
  mainViewExample: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(indents.thirtyTwo),
    border: `solid ${borderColors.lightGrey} 1px`,
    borderRadius: borderRadius.small,
    padding: theme.spacing(indents.thirtyTwo),
  },
  fullScreenExample: {
    backgroundColor: 'black',
    position: 'relative',
    height: '78%',
    padding: indents.eight,
  },
  codeWrapperOuter: {
    height: '400px',
    overflow: 'hidden',
  },
  codeWrapperInner: {
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
