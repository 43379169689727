import { action, makeObservable, observable } from 'mobx';

export interface IPaginationDto {
  pageIndex: number;
  pageSize: number;
}

export default class PaginationParams {
  public totalCount: number = 0;
  public pageIndex: number = 1;
  public pageSize: number = 5;

  constructor(pageIndex?: number, pageSize?: number) {
    makeObservable(this, {
      totalCount: observable,
      pageIndex: observable,
      pageSize: observable,

      setTotalCount: action.bound,
      updatePageIndex: action.bound,
      updatePageSize: action.bound,
      clear: action,
    });

    if (pageIndex) {
      this.pageIndex = pageIndex;
    }

    if (pageSize) {
      this.pageSize = pageSize;
    }
  }

  public setTotalCount(count: number) {
    this.totalCount = count;
  }

  public updatePageIndex(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  public updatePageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  public getPageIndexLikeQueryParam(paramName: string): string {
    return `${paramName}=${this.pageIndex}&`;
  }

  public getPageSizeQueryParam(paramName: string): string {
    return this.pageSize !== 0 ? `${paramName}=${this.pageSize}&` : '';
  }

  public getDto(): IPaginationDto {
    return { pageIndex: this.pageIndex, pageSize: this.pageSize };
  }

  public clear() {
    this.totalCount = 0;
    this.pageIndex = 0;
    this.pageSize = 0;
  }
}
