import React from 'react';

import { ButtonExampleTypes } from '../Buttons';

import PrimaryButtons from './Examples/PrimaryButtons/PrimaryButtons';
import PrimarySizedButtons from './Examples/PrimarySizedButtons/PrimarySizedButtons';
import SecondarySizedButtons from './Examples/SecondarySizedButtons/SecondarySizedButtons';
import ColoredButtons from './Examples/ColoredButtons/ColoredButtons';
import ButtonsWithIcon from './Examples/ButtonsWithIcon/ButtonsWithIcon';
import IconButtons from './Examples/IconButtons/IconButtons';
import IconButtonsSized from './Examples/IconButtonsSized/IconButtonsSized';
import IconButtonsColored from './Examples/IconButtonsColored/IconButtonsColored';
import ButtonsWithProgress from './Examples/ButtonsWithProgress/ButtonsWithProgress';

import useStyles from './styles';

interface IProps {
  type: ButtonExampleTypes;
}

const ButtonsGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  return (
    <>
      {type === ButtonExampleTypes.Block && <PrimaryButtons />}
      {type === ButtonExampleTypes.PrimarySized && <PrimarySizedButtons />}
      {type === ButtonExampleTypes.SecondarySized && <SecondarySizedButtons />}
      {type === ButtonExampleTypes.ButtonColored && <ColoredButtons />}
      {type === ButtonExampleTypes.IconInside && <ButtonsWithIcon />}
      {type === ButtonExampleTypes.IconWrapped && <IconButtons />}
      {type === ButtonExampleTypes.IconWrappedSized && <IconButtonsSized />}
      {type === ButtonExampleTypes.IconWrappedColored && <IconButtonsColored />}
      {type === ButtonExampleTypes.ButtonsWithProgress && <ButtonsWithProgress />}
    </>
  );
};

export default ButtonsGroups;
