import { makeStyles } from '@mui/styles';

import { indents, specialColors } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  exampleWrapper: {
    marginRight: theme.spacing(indents.sixteen),
  },
  rubius: {
    color: `${specialColors.mediumRed} !important`,
  },
  buttonsRow: {
    display: 'flex',
    marginTop: theme.spacing(indents.sixteen),
  },
  exampleRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconButtonWrapper: {
    margin: theme.spacing(indents.sixteen),
  },
}));

export default useStyles;
