import React, { useState, MouseEvent, ReactNode } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterList from '@mui/icons-material/FilterList';
import Popover from '@mui/material/Popover';

import InfoCard from 'Elements/GropuingGroup/InfoCard';

import useStyles from './styles';

interface IProps {
  className?: string;
  hasValue?: boolean;
  children?: ReactNode;
  onFilter?: () => void;
  onClear?: () => void;
}

const Filter = (props: IProps) => {
  const { className, hasValue, children, onFilter, onClear } = props;

  const c = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleFilter = () => {
    if (onFilter) {
      onFilter();
    }

    handleClose();
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    handleClose();
  };

  return (
    <>
      <IconButton className={className} size="small" onClick={handleClick}>
        <FilterList className={hasValue ? c.active : ''} width={16} />
      </IconButton>

      <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <InfoCard className={c.card}>
          <div className={c.content}>{children}</div>

          <div className={c.actions}>
            <Button variant="contained" color="secondary" onClick={handleFilter}>
              Фильтровать
            </Button>

            <Button color="primary" onClick={handleClear}>
              Очистить
            </Button>
          </div>
        </InfoCard>
      </Popover>
    </>
  );
};

export default Filter;
