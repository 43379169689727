import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

import { TextS } from 'Elements/TextWrappers';
import { IconButton } from 'Elements/Buttons';

import { appStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  navItems: string[];
  onSelect(id: string): void;
}

const ExampleNavigator = (props: IProps) => {
  const c = useStyles();

  const [current, setCurrent] = useState('-1');
  const [isExpanded, setIsExpanded] = useState(true);

  const { onSelect, navItems } = props;

  useEffect(() => {
    setCurrent(appStore.currentNavName);
  }, [appStore.currentNavName]);

  const onExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={isExpanded ? clsx(c.navigatorWrapper, c.expanded) : clsx(c.navigatorWrapper, c.collapsed)}>
      <div className={c.titleRow} onClick={onExpand}>
        <TextS className={c.title} text={'Содержание:'} />

        <IconButton
          className={c.navigatorSizeControl}
          icon={isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={onExpand}
        />
      </div>

      {isExpanded &&
        navItems.map(item => {
          const onCurrent = () => {
            setCurrent(item);
            onSelect(item);
          };

          const classes =
            current === item || appStore.currentNavName === item
              ? `${c.currentItem} ${c.navItemWrapper}`
              : c.navItemWrapper;

          return (
            <div key={item} className={classes} onClick={onCurrent}>
              <div>{item}</div>
            </div>
          );
        })}
    </div>
  );
};

export default observer(ExampleNavigator);
