import React from 'react';
import clsx from 'clsx';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';

import { AuthModuleGroups } from './Components';

import useStyles from './styles';

interface IProps {
  className?: string;
}

export enum AuthModuleTypes {
  First = 'Вариант 1',
  Second = 'Вариант 2',
}

const AuthModules = (props: IProps) => {
  const c = useStyles();

  const { className } = props;

  const items = [AuthModuleTypes.First, AuthModuleTypes.Second];

  return (
    <ExamplesWrapper
      render={param => <AuthModuleGroups type={param} />}
      title={'Окно авторизации (AuthModule'}
      description={'Реализует окно авторзиации. Содержит реализацию класса авторизации и работу с local storage'}
      examples={items}
    />
  );
};

export default AuthModules;
