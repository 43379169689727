export const fontsDefinition = {
  subtitle1: {
    fontName: 'Roboto',
    size: 18,
    weight: 400,
  },
  subtitle2: {
    fontName: 'Roboto',
    size: 16,
    weight: 400,
  },
  body1: {
    fontName: 'Roboto',
    size: 16,
    weight: 400,
  },
  body2: {
    fontName: 'Roboto',
    size: 14,
    weight: 400,
  },
  caption: {
    fontName: 'Roboto',
    size: 14,
    weight: 400,
  },
  button: {
    fontName: 'Roboto',
    size: 16,
    weight: 400,
  },
  menu: {
    fontName: 'Roboto',
    size: 14,
    weight: 400,
  },
  h5: {
    fontName: 'Roboto',
    size: 24,
    weight: 400,
  },
};
