import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  selectedTextRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px',
  },
  searchWrapper: {
    width: 300,
  },
}));

export default useStyles;
