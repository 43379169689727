import { ISelectItem } from '../Shared/Interfaces';
import { action, makeObservable, observable } from 'mobx';

class LiveSearchStore {
  public isPending = false;

  public allData: ISelectItem[] = [
    { value: '1', label: 'One' },
    { value: '2', label: 'Two' },
    { value: '3', label: 'Three' },
    { value: '4', label: 'Four' },
    { value: '5', label: 'Five' },
    { value: '6', label: 'Six' },
    { value: '7', label: 'Seven' },
    { value: '8', label: 'Eight' },
    { value: '9', label: 'Nine' },
    { value: '10', label: 'Ten'},
    { value: '21', label: 'Twenty One' },
    { value: '22', label: 'Twenty Two' },
    { value: '23', label: 'Twenty Three' },
    { value: '24', label: 'Twenty Four' },
    { value: '25', label: 'Twenty Five' },
    { value: '26', label: 'Twenty Six' },
    { value: '27', label: 'Twenty Seven' },
    { value: '28', label: 'Twenty Eight' },
    { value: '29', label: 'Twenty Nine' },
    { value: '210', label: 'Twenty Ten'}
  ];

  public resultList: ISelectItem[] = [];

  public selectedItem: ISelectItem | null = null;

  constructor() {
    makeObservable(this, {
      isPending: observable,
      resultList: observable,
      selectedItem: observable,

      onSearch: action.bound,
      setSelected: action.bound,
    });
  }

  public onSearch(pattern: string | null) {
    if (!pattern) {
      this.resultList = [];

      return;
    }

    this.isPending = true;
    setTimeout(() => {
      this.doSearch(pattern);
      this.isPending = false;
    }, 500);
  }

  public setSelected(selected: string | null) {
    if (!selected) {
      this.selectedItem = null;
      return;
    }

    const result = this.resultList.find(item => item.value === selected);
    if (!result) return;

    this.selectedItem = result;
  }

  private doSearch(pattern: string) {
    if (pattern === null) {
      this.resultList = [];
      return;
    }

    this.resultList = this.allData.filter(item =>
      item.label.toLocaleLowerCase().includes(pattern.toLocaleLowerCase().trim())
    );
  }
}

export default new LiveSearchStore();
