import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  authForm2: {
    display: 'flex',
    flexDirection: 'column',
    width: '25vw',
    overflow: 'auto',
    minWidth: '200px',
  },
}));

export default useStyles;
