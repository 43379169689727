import { makeAutoObservable } from 'mobx';
import { ISelectItem } from '../../Shared/Interfaces';

export enum Resolution {
  Custom = 'Custom',
  FitContent = 'Fit-content',
  H720D = '1280x720',
  WSXGAPlus = '1680x1050',
  HD1080 = '1920x1080',
}

interface IFullScreenExampleParamsDef {
  exampleName: string;
  width: string;
  height: string;
  resolution: Resolution;
  resolutions: ISelectItem[];
  onChangeWidth(width: string): void;
  onChangeHeight(height: string): void;
  pinExample(exampleName: string): void;
  dropDefault(): void;
  getHeight(exampleName: string): string;
  getWidth(exampleName: string): string;
}

export default class FullScreenExampleParams implements IFullScreenExampleParamsDef {
  public exampleName = '';

  public width = '';

  public height = '';

  public resolutions = [
    { value: Resolution.Custom, label: Resolution.Custom },
    { value: Resolution.FitContent, label: Resolution.FitContent },
    { value: Resolution.H720D, label: Resolution.H720D },
    { value: Resolution.WSXGAPlus, label: Resolution.WSXGAPlus },
    { value: Resolution.HD1080, label: Resolution.HD1080 },
  ];

  public resolution = this.resolutions[1].value;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public onChangeWidth = (width: string): void => {
    this.width = width;
    this.resolution = this.resolutions[0].value;
  };

  public onChangeHeight = (height: string): void => {
    this.height = height;
    this.resolution = this.resolutions[0].value;
  };

  public rotateDisplay = (): void => {
    const value1 = this.width;
    const value2 = this.height;

    this.width = value2;
    this.height = value1;
  };

  public onSetResolution = (value: string): void => {
    this.resolution = value as Resolution;

    if (value === Resolution.FitContent) {
      this.width = '';
      this.height = '';

      return;
    }

    const digits = value.split('x');

    if (!parseInt(digits[0], 10) || !parseInt(digits[1], 10)) return;

    this.width = digits[0];
    this.height = digits[1];
  };

  public pinExample(exampleName: string): void {
    this.exampleName = exampleName;
  }

  public dropDefault(): void {
    this.onSetResolution(Resolution.FitContent);
  }

  public getHeight(exampleName: string): string {
    return this.exampleName === exampleName ? this.height : '';
  }

  public getWidth(exampleName: string): string {
    return this.exampleName === exampleName ? this.width : '';
  }
}
