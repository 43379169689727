import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Filter from '../Filter';

import { ITableColumn } from '../../index';

import useStyles from './styles';

interface IProps {
  columns: ITableColumn[];
  className?: string;
  order?: 'asc' | 'desc';
  orderBy?: string;
  onSort?: (event: MouseEvent<unknown>, property: string | undefined) => void;
}

const EnhancedTableHeader = (props: IProps) => {
  const { columns, className, order, orderBy, onSort } = props;

  const c = useStyles();

  const renderHeader = (cols: ITableColumn[]) => {
    return cols.map(column => {
      const isSortActive = orderBy === column.key;

      const handleSort = (event: MouseEvent<unknown>) => {
        if (!onSort) return;

        if (isSortActive) {
          onSort(event, order === 'asc' || !order ? column.key : undefined);
        } else {
          onSort(event, column.key);
        }
      };

      const getLabel = () => {
        if (column.sortable) {
          return (
            <TableSortLabel active={isSortActive} direction={isSortActive ? order : 'asc'} onClick={handleSort}>
              {column.label}
            </TableSortLabel>
          );
        }

        return column.label;
      };

      return (
        <TableCell
          variant="head"
          key={column.key}
          align={column.align}
          style={{ maxWidth: column.maxWidth, width: column.width }}
          sortDirection={isSortActive ? order : false}>
          {getLabel()}

          {column.filter && (
            <Filter
              className={c.filter}
              hasValue={column.hasFilterValue ? column.hasFilterValue() : false}
              onFilter={column.onSaveFilter}
              onClear={column.onClearFilter}>
              {column.filter()}
            </Filter>
          )}
        </TableCell>
      );
    });
  };

  return (
    <TableHead className={className}>
      <TableRow>{renderHeader(columns)}</TableRow>
    </TableHead>
  );
};

export default observer(EnhancedTableHeader);
