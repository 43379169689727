import React from 'react';

import clsx from 'clsx';

import Button from '@mui/material/Button';

import { TextBtn } from 'Elements/TextWrappers';
import { Spinner } from 'Elements/GropuingGroup';

import { SizeSpinner } from 'Elements/GropuingGroup/Spinner';
import { ButtonSize, VariantButton } from './types';
import { IColorDef } from 'themes/types/interfaces';

import useStyles from './styles';

interface IProps {
  buttonClassName?: string;
  captionClassName?: string;
  color?: IColorDef;
  caption?: string;
  captionRender?: JSX.Element;
  disabled?: boolean;
  disableRipple?: boolean;
  endIcon?: JSX.Element;
  isPending?: boolean;
  startIcon?: JSX.Element;
  size?: ButtonSize;

  onClick(): void;
}

const PrimaryButton = (props: IProps) => {
  const {
    buttonClassName,
    captionClassName,
    caption,
    captionRender,
    color,
    disabled,
    disableRipple,
    endIcon,
    isPending,
    startIcon,
    size,
    onClick,
  } = props;

  const c = useStyles({ color });

  let classNameBySize = c.mdButtonClass;
  let captionTextSize = null;

  switch (size) {
    case ButtonSize.Sm: {
      classNameBySize = c.smButtonClass;
      captionTextSize = 14;
      break;
    }

    case ButtonSize.Md: {
      classNameBySize = c.mdButtonClass;
      break;
    }

    case ButtonSize.Lg: {
      classNameBySize = c.lgButtonClass;
      captionTextSize = 22;
      break;
    }
  }

  const defaultCaption = (
    <>
      <Spinner size={SizeSpinner.Md} isLoading={isPending || false} />
      <TextBtn
        size={captionTextSize}
        className={captionClassName || disabled ? c.disabledButtonCaption : c.primaryCaption}
        text={caption}
      />
    </>
  );

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      focusVisibleClassName={c.focusedButton}
      className={buttonClassName ? buttonClassName : clsx(classNameBySize, c.primaryButton)}
      disabled={disabled}
      disableRipple={disableRipple !== false}
      variant={VariantButton.Contained}
      onClick={onClick}>
      {captionRender ? captionRender : defaultCaption}
    </Button>
  );
};

export default PrimaryButton;
