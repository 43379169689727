import { makeStyles, DefaultTheme } from '@mui/styles';

import { ISizePreview } from './ImageUploader';
import {fontColors, indents} from "../../themes/materialTheme";

const useStyles = makeStyles<DefaultTheme, ISizePreview>((theme: DefaultTheme) => ({
  imageInputLabel: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imageInputTitle: {
    lineHeight: '150%',
  },
  imageNotValid: {
    color: theme.palette.error.main,
    paddingTop: 5,
    lineHeight: '150%',
  },
  fileInput: {
    display: 'none',
  },
  imageTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: indents.eight,
    },
  },
  imagePreview: props => ({
    position: 'relative',
    width: props.width,
    height: props.height,
  }),
  image: {
    width: '100%',
    height: 'auto',
  },
  icon: {
    marginRight: theme.spacing(3),
  },
  closeIcn: {
    color: fontColors.lightGrey,
    '&:hover': {
      color: fontColors.white,
    },
  },
}));

export default useStyles;
