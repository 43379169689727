import { makeStyles } from '@mui/styles';

import { bgColors } from '../../themes/materialTheme';

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    position: 'relative',
  },
  bodyWrapper: {
    display: 'flex',
    height: 'calc(100vh - 100px)',
  },
  examplesWrapper: {
    width: '85vw',
    height: 'calc(100vh - 100px)',
  },

  propPanel: {
    zIndex: 9,
    width: 500,
    height: 'calc(100vh - 100px)',
    position: 'absolute',
    backgroundColor: bgColors.white,
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
    right: 12,
  },
}));

export default useStyles;
