import { makeStyles } from '@mui/styles';

import { bgColors, indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  displayControl: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: bgColors.white,
    zIndex: 2,
    position: 'absolute',
    right: indents.eight,
    top: -indents.eight,
  },
  displayControlSelect: {
    marginLeft: indents.eight,
  },
  displayControlItem: {
    marginLeft: indents.eight,
  },
  displaySizeInput: {
    width: '80px',
  },
  displaySizeSelect: {
    width: '120px',
  },
}));

export default useStyles;
