import React from 'react';

import { ITextWrapperProps } from '../types';

import useStyles from './styles';

const TextB1 = (props: ITextWrapperProps) => {
  const { className, children, color, id, ref, fontWeight, noWrap, text } = props;

  const c = useStyles({ fontWeight, color, noWrap });

  return (
    <span className={className ? `${className} ${c.textB1}` : c.textB1} id={id} ref={ref}>
      {children ? children : text}
    </span>
  );
};

export default TextB1;
