import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useDebounce } from './Components';

import { IconButton } from 'Elements/Buttons';
import { TextInputSize, TextInput, VariantInput } from 'Elements/InputsGroup/TextInput';
import { Spinner } from 'Elements/GropuingGroup';
import { SizeSpinner } from 'Elements/GropuingGroup/Spinner';

import { KeyCodes } from './types';

import { ISelectItem } from 'Shared/Interfaces';

import useStyles from './styles';

interface IProps {
  className?: string;
  debounceTime?: number;
  isPending?: boolean;
  resultCount?: number;
  resultList: ISelectItem[];
  size?: TextInputSize;
  variant?: VariantInput;

  setSelected: (selectedId: string | null) => void;
  onSearch: (pattern: string | null) => void;
}

/* Дефолтное время задержки запроса */
const DEFAULT_DEBOUNCE_TIME = 500;

const LiveSearch = (props: IProps) => {
  const { className, debounceTime, isPending, setSelected, size, onSearch, resultList, variant } = props;

  const c = useStyles({ size });

  const [index, setIndex] = useState(0);
  const [prevValue, setPrevValue] = useState('');
  const [value, setValue] = useState('');
  const [isSearch, setIsSearch] = useState(true);

  const pattern = useDebounce(value, debounceTime || DEFAULT_DEBOUNCE_TIME);

  useEffect(() => {
    if (pattern.length > 0 && isSearch) onSearch(value);
  }, [pattern]);

  const onClickAway = () => {
    onSearch(null);
    setIndex(0);
  };

  const onChangePattern = (newValue: string) => {
    setIsSearch(true);
    setPrevValue(newValue);
    setValue(newValue);
  };

  const onClearPattern = () => {
    onSearch(null);
    setSelected(null);
    setValue('');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KeyCodes.ArrowUp) {
      setIndex(index > 0 ? index - 1 : resultList.length - 1);
      setPrevValue(value);
      onSelect(resultList[index].value);
    }

    if (e.key === KeyCodes.ArrowDown) {
      setIndex(index < resultList.length - 1 ? index + 1 : 0);
      onSelect(resultList[index].value);
    }

    if (e.key === KeyCodes.Enter) {
      onSelect(resultList[index].value);
      setSelected(resultList[index].value);
      onSearch(null);
    }

    if (e.key === KeyCodes.Escape) {
      onChangePattern(prevValue);
      onSearch(null);
    }
  };

  const onSelect = (id: string) => {
    const selected = resultList.find(item => item.value === id);

    if (!selected) return;

    setIsSearch(false);
    setValue(selected.label);
  };

  const onChoose = (id: string) => {
    setSelected(id);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div onKeyDown={onKeyDown} className={className ? clsx(className, c.wrapper) : c.wrapper}>
        <TextInput
          variant={variant}
          value={value}
          endAdornment={
            isPending ? (
              <Spinner isLoading={isPending} size={SizeSpinner.Sm} />
            ) : (
              <IconButton icon={value ? <Close /> : <Search />} onClick={onClearPattern} />
            )
          }
          onChange={onChangePattern}
        />

        {resultList.length > 0 && (
          <div className={c.resultWrapper}>
            {resultList.map(item => {
              const onCLickItem = () => {
                onSelect(item.value);
                onChoose(item.value);
              };

              return (
                <div
                  key={item.value}
                  className={resultList[index].value === item.value ? clsx(c.resultItem, c.current) : c.resultItem}
                  onMouseDown={onCLickItem}>
                  {item.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default LiveSearch;
