import React from 'react';

import { Select } from '@mui/material';
import { InputLabel } from '@mui/material';
import { MenuItem } from '@mui/material';

import { Spinner } from '../../GropuingGroup';
import { SizeSpinner } from '../../GropuingGroup/Spinner';
import { ISelectItem } from 'Shared/Interfaces';

import { TextC } from 'Elements/TextWrappers';

import useStyles from './styles';
import { IconButton } from '../../Buttons';
import Close from '@mui/icons-material/Close';

interface IProps {
  className?: string;
  error?: boolean;
  itemsWrapperClass?: string;
  disabled?: boolean;
  height?: number;
  label?: string;
  isLoading?: boolean;
  items: ISelectItem[];
  values: string[];
  onChange(selected: any[]): void;
}

const SelectMultiple = (props: IProps) => {
  const { className, error, itemsWrapperClass, disabled, height, isLoading, items, label, onChange, values } = props;

  const c = useStyles({ height });

  const onChangeValue = (event: any) => {
    onChange(event.target.value as string[]);
  };

  const renderSelected = (values: any) => {
    const selectedValues = items.filter(item => values.includes(item.value));

    return (
      <div className={c.selectedItemsWrapper}>
        {selectedValues.map(item => {
          const onRemoveItem = (e: any) => {
            e.preventDefault();
          };

          return (
            <div className={c.selectedItem} onClick={onRemoveItem}>
              <TextC className={c.selectedItemName} key={item.value} text={item.label} />
              <IconButton
                className={c.itemIcon}
                hint={'Description'}
                icon={<Close className={c.removeItemBtn} />}
                onClick={onRemoveItem}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={`${className} ${c.wrapper}`}>
      <Spinner isLoading={!!isLoading} size={SizeSpinner.Sm} />
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        onClick={(e) => {console.log(e)}}
        className={c.selectWrapper}
        error={error}
        disabled={disabled}
        multiple={true}
        variant="outlined"
        labelId="select-label"
        value={values}
        renderValue={renderSelected}
        onChange={onChangeValue}>
        {items.map(item => (
          <MenuItem className={itemsWrapperClass || c.listItems} key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectMultiple;
