import { IAuthData } from 'Elements/Solutions/AuthModule/AuthModule';

class UsersApiService {
  public createUser(authData: IAuthData): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => resolve(authData.login.length > 0 && authData.password.length > 0), 1000);
    });
  }
}

export default new UsersApiService();
