import { IAuthData } from 'Elements/Solutions/AuthModule/AuthModule';

class UserAuthApiService {
  public authUser(authData: IAuthData): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => resolve(authData.login.length > 0 && authData.password.length > 0), 500);
    });
  }
}

export default new UserAuthApiService();
