import { DefaultTheme, makeStyles } from '@mui/styles';

interface IProps {
  color?: string;
}

const useStyles = makeStyles<DefaultTheme, IProps>(theme => ({
  icon: props => ({
    color: `${props.color} !important` || '',
  }),
}));

export default useStyles;
