import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  exampleActionsRowWrapper: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    marginTop: theme.spacing(indents.sixteen),
    marginBottom: theme.spacing(indents.sixteen),
  },
  exampleActionBtn: {
    paddingLeft: theme.spacing(indents.sixteen),
    marginBottom: theme.spacing(indents.eight),
  },
  actionBtnIcon: {},
}));

export default useStyles;
