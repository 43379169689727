import React from 'react';

import { PrimaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from '../../styles';

const PrimaryButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Md} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Lg} onClick={() => {}} caption={'Apply'} />
      </div>
    </div>
  );
};

export default PrimaryButtons;
