import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  elementsTreeWrapper: {
    margin: theme.spacing(indents.sixteen),
    paddingBottom: theme.spacing(indents.eight),
    minWidth: '150px',
    width: '15vw',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  searchElemsWrapper: {
    marginBottom: theme.spacing(indents.eight),
  },
  elementsTree: {
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    overflowX: 'clip',
  },
  elemsGroup: {
    margin: theme.spacing(indents.eight),
  },
  elemsGroupItem: {
    '& .MuiTreeItem-label': {
      padding: theme.spacing(indents.eight),
    },
  },
  elementIsNotExist: {
    color: 'grey',
  },
  treeItemRow: {
    paddingLeft: theme.spacing(indents.eight),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  treeItemRowArrow: {
    color: 'grey',
    position: 'absolute',
    left: 8,
    top: 8,
  },
}));

export default useStyles;
