import { makeStyles } from '@mui/styles';

import { borderRadius, indents, borderColors } from 'themes/materialTheme';

interface IProps {
  height?: number;
  isLightBg?: boolean;
}

const useStyles = makeStyles<null, IProps>({
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  selectWrapper: props => ({
    textAlign: 'left',
    height: props.height || 40,
    marginRight: 24,
    width: '100%',
    borderRadius: borderRadius.medium,
    '& .MuiOutlinedInput-input': {
      padding: `${indents.four}px ${indents.eight}px`,
    },
    '& fieldset': {
      borderColor: borderColors.mediumBlue,
      borderRadius: borderRadius.medium,
    },
    '&:hover fieldset': {
      borderColor: borderColors.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: borderColors.darkBlue,
    },
  }),
  items: {
    backgroundColor: borderColors.mediumBlue,
  },
  itemIcon: {
    marginRight: '16px',
  },
});

export default useStyles;
