import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { TableGroups } from './Components';

import useStyles from './styles';

export enum TableTypes {
  BaseTable = 'Базовая таблица',
}

const Table = () => {
  const c = useStyles();

  const items = [TableTypes.BaseTable];

  return (
    <ExamplesWrapper
      render={param => <TableGroups type={param} />}
      title={'Таблица (Table)'}
      description={
        'Таблица реализует представление списка данных. Располагает возможностью серверной или фронтовой пагинации, сортировки и фильтрации данных.'
      }
      examples={items}
    />
  );
};

export default Table;
