import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { IconButtonSize, IIconButtonProps } from './types';
import useStyles from './styles';
import clsx from 'clsx';

const IconButtonW = (props: IIconButtonProps) => {
  const { className, color, disabled, hint, icon, onClick, size } = props;

  const c = useStyles({ color });

  return (
    <Tooltip title={hint || ''}>
      <IconButton
        className={className ? clsx(className, c.icon) : c.icon}
        disabled={disabled}
        size={size || IconButtonSize.Medium}
        onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonW;
