import React, { useState } from 'react';

import Snackbar from '@mui/material/Snackbar';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BuildIcon from '@mui/icons-material/Build';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ConstructionIcon from '@mui/icons-material/Construction';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';

import { IconButton, IconButtonSize } from 'Elements/Buttons';

import useStyles from './styles';

interface IProps {
  isExpanded: boolean;
  isShowOnlyExampleCode: boolean;
  dropToDefault(): void;
  expandToggle(value: boolean): void;
  exampleCodeToggle(value: boolean): void;
  onCopyCode(): boolean;
}

const ExampleActionsRow = (props: IProps) => {
  const c = useStyles();

  const { dropToDefault, isExpanded, isShowOnlyExampleCode, expandToggle, exampleCodeToggle, onCopyCode } = props;

  const [isShowCopyResult, setIsShowCopyResult] = useState(false);

  const goToTheCodeBox = () => {
    window.location.href = 'https://codesandbox.io/s/strange-robinson-p8vbz?file=/src/App.js';
  };

  const onClickExpand = () => {
    expandToggle(!isExpanded);
  };

  const onClickShowAllCode = () => {
    exampleCodeToggle(!isShowOnlyExampleCode);
  };

  const onClickDropToDefault = () => {
    dropToDefault();
  };

  const onCopyClick = () => {
    const result = onCopyCode();
    if (!result) return;

    setIsShowCopyResult(result);
  };

  const onCloseCopyResult = () => {
    setIsShowCopyResult(false);
  };

  const expandBtn = <AspectRatioIcon className={c.actionBtnIcon} />;
  const slipBtn = <SettingsOverscanIcon className={c.actionBtnIcon} />;
  const showAllCode = <CodeOffIcon fontSize={IconButtonSize.Small} />;
  const showCode = <CodeIcon fontSize={IconButtonSize.Small} />;

  return (
    <div className={c.exampleActionsRowWrapper}>
      <IconButton
        className={c.exampleActionBtn}
        size={IconButtonSize.Large}
        hint={'Drop to default'}
        icon={<AutorenewIcon />}
        onClick={onClickDropToDefault}
      />

      <IconButton
        className={c.exampleActionBtn}
        size={IconButtonSize.Large}
        hint={isShowOnlyExampleCode ? 'Show all code' : 'Show only example code'}
        icon={isShowOnlyExampleCode ? showCode : showAllCode}
        onClick={onClickShowAllCode}
      />

      <IconButton
        className={c.exampleActionBtn}
        size={IconButtonSize.Large}
        hint={'Copy code'}
        icon={<ContentCopyIcon fontSize={IconButtonSize.Small} />}
        onClick={onCopyClick}
      />

      <IconButton
        className={c.exampleActionBtn}
        hint={'Show params'}
        size={IconButtonSize.Large}
        icon={<BuildIcon fontSize={IconButtonSize.Small} />}
        onClick={() => {}}
      />

      <IconButton
        className={c.exampleActionBtn}
        hint={'Go to the code box'}
        size={IconButtonSize.Large}
        icon={<ConstructionIcon className={c.actionBtnIcon} fontSize={IconButtonSize.Small} />}
        onClick={goToTheCodeBox}
      />

      <IconButton
        className={c.exampleActionBtn}
        hint={!isExpanded ? 'Show full screen' : 'Minimize'}
        icon={isExpanded ? expandBtn : slipBtn}
        onClick={onClickExpand}
      />

      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={isShowCopyResult}
        autoHideDuration={1500}
        onClose={onCloseCopyResult}
        message="Done!"
      />
    </div>
  );
};

export default ExampleActionsRow;
