import React, { useState } from 'react';

import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Done from '@mui/icons-material/Done';

import { InputExampleTypes } from '../Inputs';

import { TextInput, TextInputSize, VariantInput } from 'Elements/InputsGroup/TextInput';
import { IconButton } from 'Elements/Buttons';

import useStyles from './styles';

interface IProps {
  type: InputExampleTypes;
}

const InputsGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  const [text, setText] = useState('');

  const onSetText = (value: string) => {
    setText(value);
  };

  const onIconClick = () => {
    if (text) setText('');
  };

  const baseExamples = (
    <div className={c.rowWrapper}>
      <TextInput className={c.inputWrapper} variant={VariantInput.Outlined} value={text} onChange={onSetText} />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
        error={true}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
        disabled={true}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
        password={true}
      />
    </div>
  );

  const sizedExamples = (
    <div className={c.rowWrapper}>
      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
      />
      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        onChange={onSetText}
      />
    </div>
  );

  const withIconsAndButtons = (
    <div className={c.rowWrapper}>
      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        endAdornment={<IconButton icon={text ? <Close /> : <Search />} onClick={onIconClick} />}
        onChange={onSetText}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        disabled={true}
        endAdornment={<IconButton disabled={true} icon={text ? <Close /> : <Search />} onClick={onIconClick} />}
        onChange={onSetText}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        error={true}
        endAdornment={<IconButton icon={text ? <Close /> : <Search />} onClick={onIconClick} />}
        onChange={onSetText}
      />

      <TextInput
        className={c.inputWrapper}
        variant={VariantInput.Outlined}
        value={text}
        endAdornment={text ? <Done className={c.successIcon} /> : undefined}
        onChange={onSetText}
      />
    </div>
  );

  return (
    <>
      {type === InputExampleTypes.BaseExamples && baseExamples}
      {type === InputExampleTypes.SizedExamples && sizedExamples}
      {type === InputExampleTypes.WithIconsAndButtons && withIconsAndButtons}
    </>
  );
};

export default InputsGroups;
