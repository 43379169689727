import React from 'react';

import { IconButton } from 'Elements/Buttons';

import CameraAlt from '@mui/icons-material/CameraAlt';
import Check from '@mui/icons-material/Check';
import Create from '@mui/icons-material/Create';
import ControlPointDuplicate from '@mui/icons-material/ControlPointDuplicate';
import Close from '@mui/icons-material/Close';
import EventAvailable from '@mui/icons-material/EventAvailable';

import { specialColors } from 'themes/materialTheme';

import useStyles from '../../styles';

const IconButtonsColored = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <>
        <div className={c.exampleWrapper}>
          <IconButton color={specialColors.lightBlue} hint={'Description'} icon={<CameraAlt />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton color={specialColors.mediumBlue} hint={'Description'} icon={<Check />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton color={specialColors.darkBlue} hint={'Description'} icon={<Create />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton
            color={specialColors.lightGreen}
            hint={'Description'}
            icon={<ControlPointDuplicate />}
            onClick={() => {}}
          />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton
            color={specialColors.lightRed}
            hint={'Description'}
            icon={<EventAvailable />}
            onClick={() => {}}
          />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton color={specialColors.darkRed} hint={'Description'} icon={<Close />} onClick={() => {}} />
        </div>
      </>
    </div>
  );
};

export default IconButtonsColored;
