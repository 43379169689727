import { makeStyles } from '@mui/styles';
import {indents} from "themes/materialTheme";

const useStyles = makeStyles(mTheme => ({
  textWrapper: {
    margin: mTheme.spacing(indents.eight),
  },
}));

export default useStyles;
