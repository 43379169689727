export const bgColors = {
  white: '#FFF',
  veryLightGrey: '#EAEAEA',
  lightGrey: '#BEBEBE',
  grey: '#676767',
  darkGrey: '#373737',
  deepDarkGrey: '#242424',
  lightBlue: '#3f8ec2',
  mediumBlue: '#0f6cac',
  darkBlue: '#0a5180',
};

export const fontColors = {
  white: '#FFF',
  lightGrey: '#A7A7A7',
  mediumGrey: '#4C4C4C',
  darkBlue: '#002440',
  darkest: '#020000',
  black: '#000',
};

export const borderColors = {
  white: '#FFF',
  lightGrey: '#EEE',
  mediumGrey: '#999',
  darkGrey: '#474646',
  lightBlue: '#3f8ec2',
  mediumBlue: '#0f6cac',
  darkBlue: '#0a5180',
};

export const specialColors = {
  lightOrange: '#ff6d36',
  mediumOrange: '#c13806',
  darkOrange: '#6b2303',

  lightGreen: '#04a232',
  mediumGreen: '#04a232',
  darkGreen: '#047e28',

  lightBlue: '#3f8ec2',
  mediumBlue: '#0f6cac',
  darkBlue: '#0a5180',

  lightRed: '#ea6363',
  mediumRed: '#d01f1f',
  darkRed: '#9d1e1e',
};