import { makeStyles } from '@mui/styles';

import { bgColors, borderColors, borderRadius, indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  navigatorWrapper: {
    backgroundColor: '#FFF',
    zIndex: 2,
    position: 'absolute',
    right: theme.spacing(indents.thirtyTwo),
    border: `solid ${borderColors.lightGrey} 1px`,
    borderRadius: `${borderRadius.small}px`,
    marginLeft: 200,
  },
  expanded: {
    padding: theme.spacing(indents.eight),
  },
  collapsed: {
    padding: theme.spacing(indents.four),
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    margin: theme.spacing(indents.eight),
    padding: theme.spacing(indents.eight),
  },
  navigatorSizeControl: {
    marginLeft: 'auto',
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(indents.eight),
  },
  currentItem: {
    backgroundColor: bgColors.veryLightGrey,
  },
  navItemWrapper: {
    borderRadius: `${borderRadius.small}px`,
    display: 'flex',
    margin: theme.spacing(indents.four),
    padding: theme.spacing(indents.eight),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: bgColors.veryLightGrey,
    },
  },
}));

export default useStyles;
