import React from 'react';
import { observer } from 'mobx-react';

import { TextInputSize, VariantInput } from 'Elements/InputsGroup/TextInput';
import { TextB1 } from 'Elements/TextWrappers';
import LiveSearch from 'Elements/InputsGroup/LiveSearch';

import { SearchExampleTypes } from '../LiveSearch';

import { liveSearchStore } from 'Stores';

import useStyles from './styles';
import {FontWeight} from "../../../../../Elements/TextWrappers/types";

interface IProps {
  type: SearchExampleTypes;
}

const LiveSearchGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  const smSearch = (
    <>
      <div>
        <div className={c.selectedTextRow}>
          <TextB1 fontWeight={600}>Результат выбора:</TextB1>
          <TextB1>{liveSearchStore.selectedItem?.label}</TextB1>
        </div>

        <LiveSearch
          className={c.searchWrapper}
          setSelected={liveSearchStore.setSelected}
          isPending={liveSearchStore.isPending}
          variant={VariantInput.Outlined}
          resultList={liveSearchStore.resultList}
          onSearch={liveSearchStore.onSearch}
        />
      </div>
    </>
  );

  const mdSearch = (
    <>
      <div>
        <div className={c.selectedTextRow}>
          <TextB1 fontWeight={600}>Результат выбора:</TextB1>
          <TextB1>{liveSearchStore.selectedItem?.label}</TextB1>
        </div>

        <LiveSearch
          className={c.searchWrapper}
          setSelected={liveSearchStore.setSelected}
          isPending={liveSearchStore.isPending}
          variant={VariantInput.Outlined}
          resultList={liveSearchStore.resultList}
          onSearch={liveSearchStore.onSearch}
        />
      </div>
    </>
  );

  const lgSearch = (
    <>
      <div>
        <div className={c.selectedTextRow}>
          <TextB1 fontWeight={FontWeight.Bold} text={'Результат выбора:'} />
          <TextB1 text={liveSearchStore.selectedItem?.label} />
        </div>

        <LiveSearch
          className={c.searchWrapper}
          setSelected={liveSearchStore.setSelected}
          isPending={liveSearchStore.isPending}
          variant={VariantInput.Outlined}
          resultList={liveSearchStore.resultList}
          onSearch={liveSearchStore.onSearch}
        />
      </div>
    </>
  );

  return (
    <>
      {type === SearchExampleTypes.SmSearch && smSearch}
      {type === SearchExampleTypes.MdSearch && mdSearch}
      {type === SearchExampleTypes.LgSearch && lgSearch}
    </>
  );
};

export default observer(LiveSearchGroups);
