import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  btn: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    color: '#676767',
  },
}));

export default useStyles;
