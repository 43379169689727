import { makeStyles } from '@mui/styles';

import { appStore } from 'Stores';
import {borderRadius} from "../../../themes/materialTheme";

const useStyles = makeStyles(theme => ({
  commonTileWrapper: {
    display: 'inline-block',
    height: 'fit-content',
    width: 'fit-content',
  //  backgroundColor: '#F3F3F3',
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
    borderRadius: borderRadius.medium,
    padding: '16px',
  },
}));

export default useStyles;
