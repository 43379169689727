import React from 'react';
import clsx from 'clsx';

import { TextField, InputAdornment } from '@mui/material';

import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { VariantInput } from './types';

import useStyles from './styles';

interface IProps {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  error?: boolean;
  nativeLabel?: string;
  onChange: (value: string) => void;
  password?: boolean;
  placeholder?: string;
  startAdornment?: JSX.Element;
  value: string;
  variant?: VariantInput;
}

const TextInput = (props: IProps) => {
  const c = useStyles();
  const {
    autoFocus,
    className,
    defaultValue,
    endAdornment,
    disabled,
    error,
    nativeLabel,
    onChange,
    password,
    startAdornment,
    placeholder,
    value,
    variant,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const errorIcon = error ? (
    <InputAdornment position="end">
      <ErrorOutline className={c.errorIcon} />
    </InputAdornment>
  ) : null;

  const startPlaceContent = (
    <>
      {startAdornment}
      {errorIcon}
    </>
  );

  const endPlaceContent = (
    <>
      {endAdornment}
      {errorIcon}
    </>
  );

  const inputProps = {
    startAdornment: startAdornment ? startPlaceContent : null,
    endAdornment: endAdornment ? endPlaceContent : null,
  };

  return (
    <div className={clsx(c.inputWrapper, className || '')}>
      <TextField
        label={nativeLabel}
        value={value}
        disabled={disabled}
        type={password ? 'password' : ''}
        placeholder={placeholder}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        error={error}
        InputProps={inputProps}
        onChange={handleChange}
        variant={variant || VariantInput.Outlined}
      />
    </div>
  );
};

export default TextInput;
