import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: `calc(100vh - 100px)`,
    overflow: 'auto',
  },
  examplesDescription: {
    marginTop: theme.spacing(indents.thirtyTwo),
    textAlign: 'justify',
  },
  examplesWrapper: {
    width: '900px',
    height: 'auto',
    margin: theme.spacing(indents.sixteen),
    [theme.breakpoints.down('lg')]: {
      marginLeft: '36px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '200px',
    },
    paddingBottom: '200px',
  },
  examplesTitle: {

  },
  examplesGroup: {
    paddingTop: theme.spacing(indents.sixteen),
  },
}));

export default useStyles;
