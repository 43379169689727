import { ITableExampleRowDto } from '../Interfaces';

class TableExampleRow {
  public id: number | null = null;
  public name = '';
  public param1: number | null = null;
  public param2: number | null = null;
  public param3: number | null = null;
  public param4: number | null = null;

  public constructor(dto: ITableExampleRowDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.param1 = dto.param1;
    this.param2 = dto.param2;
    this.param3 = dto.param3;
    this.param4 = dto.param4;
  }
}

export default TableExampleRow;
