import { makeStyles, DefaultTheme } from '@mui/styles';

import { bgColors, borderColors, borderRadius, fontColors } from 'themes/materialTheme';
import { IColorDef } from '../../../themes/types/interfaces';

interface IProps {
  color?: IColorDef;
}

const useStyles = makeStyles<DefaultTheme, IProps>(theme => ({
  smButtonClass: {
    height: '32px',
    minWidth: '64px',
  },
  mdButtonClass: {
    height: '40px',
    minWidth: '96px',
  },
  lgButtonClass: {
    height: '48px',
    minWidth: '120px',
  },
  secondaryButton: props => ({
    borderRadius: borderRadius.medium,
    borderColor: `${props.color?.medium} !important` || bgColors.mediumBlue,
    '&:hover': {
      borderColor: props.color?.dark || borderColors.darkBlue,
    },
    '&:disabled': {},
  }),
  focusedButton: {
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
  },
}));

export default useStyles;
