import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { TextWrapperGroups } from './Components';

import useStyles from './styles';

export enum TextWrapperTypes {
  Body1 = 'Body1',
  Body2 = 'Body2',
  ButtonText = 'ButtonText',
  Caption = 'Caption',
  H5Text = 'H5Text',
  MenuText = 'MenuText',
  Subtitle = 'Subtitle',
  Subtitle2 = 'Subtitle2',
}

const TextWrappers = () => {
  const c = useStyles();

  const items = [
    TextWrapperTypes.Body1,
    TextWrapperTypes.Body2,
    TextWrapperTypes.ButtonText,
    TextWrapperTypes.Caption,
    TextWrapperTypes.H5Text,
    TextWrapperTypes.MenuText,
    TextWrapperTypes.Subtitle,
    TextWrapperTypes.Subtitle2,
  ];

  return (
    <ExamplesWrapper
      render={param => <TextWrapperGroups type={param} />}
      title={'Типография (TextWrapper)'}
      description={
        'Текстовый контейнер обеспечивает орагнизацию ограниченного централизованного набора вариантов пердставления текста.' +
        ' Каждый контейнер конфигуреруется в одном месте проекта, что обеспечивает одинаковое представление текста одной категории в рамка всего проекта.' +
        'Прежде всего это такие параметры как размер шрифта, сам шрифт, параметры начертания. При этом остается возможность кастомизировать текст в частном случае, например его цвет, способ переноса.'
      }
      examples={items}
    />
  );
};

export default TextWrappers;
