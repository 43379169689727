import React, { ReactNode } from 'react';

import clsx from 'clsx';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

interface IProps {
  title?: string;
  subtitle?: string;
  className?: string;
  children?: ReactNode;
}

const InfoCard = (props: IProps) => {
  const { title, subtitle, className, children } = props;

  const c = useStyles();

  return (
    <Card className={clsx(c.card, className || '')}>
      {title && (
        <Typography variant="h6" component="h6">
          {title}
        </Typography>
      )}

      {subtitle && <span className={c.subtitle}>{subtitle}</span>}

      <div className={c.content}>{children}</div>
    </Card>
  );
};

export default InfoCard;
