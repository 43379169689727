import { makeStyles } from '@mui/styles';

import { specialColors } from 'themes/materialTheme';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  controlWrapper: {
    display: 'flex',
    position: 'relative',
    width: '60%',
  },
  validationNotice: {
    position: 'absolute',
    fontSize: 12,
    left: indents.four,
    top: -indents.twentyFour,
    color: specialColors.mediumRed,
    maxWidth: '80%',
    height: 20,
    overflow: 'hidden',
  },
  inputElemsInColumnSlipLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputElemsInColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputElemsInColumnSlipRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  inputElemsInRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabelOnAbove: props => ({
    display: 'flex',
  }),
  inputLabelOnLeft: props => ({
    display: 'flex',
  }),
  requiredSign: {
    color: specialColors.mediumRed,
    marginLeft: indents.eight,
    fontSize: 16,
  },
}));

export default useStyles;
