import { makeStyles, DefaultTheme } from '@mui/styles';
import { TextInputSize } from '../TextInput';

import {borderRadius} from "../../../themes/materialTheme";

interface IProps {
  size?: TextInputSize;
}

const useStyles = makeStyles<DefaultTheme, IProps>(theme => ({
  wrapper: {
    position: 'relative',
  },
  input: {
    width: '100%',
  },
  resultWrapper: {
    minWidth: '99%',
    marginTop: theme.spacing(1),
    position: 'absolute',
    zIndex: 999,
    backgroundColor: '#fff',
    border: `solid ${theme.palette.primary.dark} 2px`,
    borderRadius: borderRadius.medium,
  },
  current: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  resultItem: props => {
    let height = '16px';

    switch (props.size) {
      case TextInputSize.Md: {
        height = '20px';
        break;
      }
      case TextInputSize.Lg: {
        height = '24px';
      }
    }

    return {
      height,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: theme.spacing(2),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
      },
    };
  },
}));

export default useStyles;
