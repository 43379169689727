const primaryButtonsFullCode =
`import React from 'react';

import { PrimaryButton, SecondaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from 'src/Modules/MainPage/ExampleGroups/Buttons/Components/styles';

const PrimaryButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Sm} disabled={true} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <SecondaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <SecondaryButton size={ButtonSize.Sm} disabled={true} onClick={() => {}} caption={'Apply'} />
      </div>
    </div>
  );
};

export default PrimaryButtons;`;

const primaryButtonsCode =
`<div className={c.wrapper}>
  <div className={c.exampleWrapper}>
    <PrimaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
  </div>

  <div className={c.exampleWrapper}>
    <PrimaryButton size={ButtonSize.Sm} disabled={true} onClick={() => {}} caption={'Apply'} />
  </div>

  <div className={c.exampleWrapper}>
    <SecondaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
  </div>

  <div className={c.exampleWrapper}>
    <SecondaryButton size={ButtonSize.Sm} disabled={true} onClick={() => {}} caption={'Apply'} />
  </div>
</div>`;

export { primaryButtonsFullCode, primaryButtonsCode };