import { Ref } from 'react';

export interface ITextWrapperProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  id?: string;
  fontWeight?: number;
  noWrap?: boolean;
  ref?: Ref<HTMLElement>;
  size?: number | null;
  text?: string;
}

export interface IStyleProps {
  fontWeight?: FontWeight;
  color?: string;
  noWrap?: boolean;
  size?: number | null;
}

export enum FontWeight {
  Thin = 100,
  ExtraLight = 200,
  Light = 300,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Heavy = 900,
}
