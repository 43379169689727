import React from 'react';
import { observer } from 'mobx-react';

import { AuthModule } from 'Elements/Solutions/AuthModule';

import { AuthModuleTypes } from '../AuthModules';

import { userAuthStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  type: AuthModuleTypes;
}

const AuthModuleGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  const firstExample = (
    <>
      <AuthModule
        authData={{ login: userAuthStore.login, password: userAuthStore.password }}
        isPending={userAuthStore.isPending}
        onSubmit={userAuthStore.onSubmit}
        onChangeLogin={userAuthStore.onChangeLogin}
        onChangePassword={userAuthStore.onChangePassword}
      />
    </>
  );

  const secondExample = (
    <>
      <AuthModule
        authData={{ login: userAuthStore.login, password: userAuthStore.password }}
        authTitle={'Введите данные авторизации'}
        className={c.authForm2}
        isPending={userAuthStore.isPending}
        onSubmit={userAuthStore.onSubmit}
        submitButtonCaption={'Авторизоваться'}
        onChangeLogin={userAuthStore.onChangeLogin}
        onChangePassword={userAuthStore.onChangePassword}
      />
    </>
  );

  return (
    <>
      {type === AuthModuleTypes.First && firstExample}
      {type === AuthModuleTypes.Second && secondExample}
    </>
  );
};

export default observer(AuthModuleGroups);
