import React from 'react';

import { IconButton, IconButtonSize } from 'Elements/Buttons';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';

import useStyles from '../../styles';

const IconButtonsSized = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <>
        <div className={c.exampleRow}>
          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Small}
              icon={<CloseIcon fontSize={IconButtonSize.Small} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Medium}
              icon={<CloseIcon fontSize={IconButtonSize.Medium} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Large}
              icon={<CloseIcon fontSize={IconButtonSize.Large} />}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className={c.exampleRow}>
          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Small}
              icon={<CheckIcon fontSize={IconButtonSize.Small} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Medium}
              icon={<CheckIcon fontSize={IconButtonSize.Medium} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Large}
              icon={<CheckIcon fontSize={IconButtonSize.Large} />}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className={c.exampleRow}>
          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Small}
              icon={<CreateIcon fontSize={IconButtonSize.Small} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Medium}
              icon={<CreateIcon fontSize={IconButtonSize.Medium} />}
              onClick={() => {}}
            />
          </div>

          <div className={c.iconButtonWrapper}>
            <IconButton
              hint={'Description'}
              size={IconButtonSize.Large}
              icon={<CreateIcon fontSize={IconButtonSize.Large} />}
              onClick={() => {}}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default IconButtonsSized;
