import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  AuthModules,
  Buttons,
  CheckBoxes,
  Inputs,
  InputWrappers,
  LiveSearch,
  Table,
  TextWrappers,
} from './ExampleGroups';
import Forms from "./ExampleGroups/Forms";

import { Header, ElementsTree } from './Components';

import { ElementNames } from 'Shared/Constatnts';

import useStyles from './styles';

const MainPage = () => {
  const c = useStyles();

  const [selectedElementId, setSelectedElementId] = useState<ElementNames | string>(ElementNames.Button);

  useEffect(() => {
    const currentNode = window.localStorage.getItem('currentNode');
    if (currentNode) {
      setSelectedElementId(currentNode);
    }
  }, []);

  const onElementClick = (elementName: ElementNames) => {
    setSelectedElementId(elementName);
    window.localStorage.setItem('currentNode', elementName);
  };

  return (
    <div className={c.mainWrapper}>
      <Header />

      <div className={c.bodyWrapper}>
        <ElementsTree selected={selectedElementId} onElementClick={onElementClick} />

        <div className={c.examplesWrapper}>
          {selectedElementId === ElementNames.Button && <Buttons />}
          {selectedElementId === ElementNames.CheckBox && <CheckBoxes />}
          {selectedElementId === ElementNames.TextInput && <Inputs />}
          {selectedElementId === ElementNames.AuthModule && <AuthModules />}
          {selectedElementId === ElementNames.Forms && <Forms />}
          {selectedElementId === ElementNames.LiveSearch && <LiveSearch />}
          {selectedElementId === ElementNames.InputGroup && <InputWrappers />}
          {selectedElementId === ElementNames.TextWrapper && <TextWrappers />}
          {selectedElementId === ElementNames.Table && <Table />}
        </div>
      </div>
    </div>
  );
};

export default observer(MainPage);
