import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    width: '250px',
    margin: '16px',
  },
  successIcon: {
    color: 'green',
  },
  tableWrapper: {
    height: '450px',
  },
}));

export default useStyles;
