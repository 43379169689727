import { makeStyles } from '@mui/styles';
import { appStore } from '../../../../Stores';
import {indents} from "../../../../themes/materialTheme";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(indents.thirtyTwo),
  },
}));

export default useStyles;
