import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { InputLabel } from '@mui/material';
import { TextC } from '../TextWrappers';

import IconButtonW from '../Buttons/IconButton/IconButton';
import AttachFile from '@mui/icons-material/AttachFile';

import useStyles from './styles';

export enum ImageFormats {
  SVG = 'SVG',
  PNG = 'PNG',
  JPG = 'JPG',
}

export enum FileAccepts {
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  JPG = 'image/jpeg',
}

interface IImageUploader {
  title: string;
  format?: ImageFormats;
  accept?: FileAccepts;
  image?: string;
  error?: boolean;
  previewSize?: ISizePreview;
  onRemoveImage(): void;
  onImageLoad(event: React.ChangeEvent<HTMLInputElement>): void;
}

export interface ISizePreview {
  width: number | string;
  height: number | string;
}

const DEFAULT_PREVIEW_WIDTH = '100%';
const DEFAULT_PREVIEW_HEIGHT = '100%';

const ImageUploader = (props: IImageUploader) => {
  const { accept, title, image, previewSize } = props;

  const c = useStyles(previewSize || { width: DEFAULT_PREVIEW_WIDTH, height: DEFAULT_PREVIEW_HEIGHT });

  const renderImage = () => {
    return (
      <div className={c.previewContainer}>
        <div className={c.imagePreview}>
          <IconButtonW className={c.removeButton} onClick={props.onRemoveImage} icon={<CloseIcon className={c.closeIcn} />} />
          <img className={c.image} src={image} alt={''} />
        </div>
      </div>
    );
  };

  const renderImageUploader = () => {
    return (
      <>
        <InputLabel className={c.imageInputLabel} htmlFor={title}>
          <AttachFile className={c.icon} fontSize="small" />

          <TextC text={title} noWrap={true} />
        </InputLabel>
        <input id={title} className={c.fileInput} type="file" accept={accept} onChange={props.onImageLoad} />
      </>
    );
  };

  return <div>{image ? renderImage() : renderImageUploader()}</div>;
};

export default ImageUploader;
