const primaryButtonsSizedFullCode =
`import React from 'react';

import { PrimaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from 'src/Modules/MainPage/ExampleGroups/Buttons/Components/styles';

const PrimaryButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton size={ButtonSize.Lg} onClick={() => {}} caption={'Apply'} />
      </div>
    </div>
  );
};

export default PrimaryButtons;
`;

const primarySizedButtonsCode =
`<div className={c.wrapper}>
    <div className={c.exampleWrapper}>
      <PrimaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
    </div>
    
    <div className={c.exampleWrapper}>
      <PrimaryButton onClick={() => {}} caption={'Apply'} />
    </div>
    
    <div className={c.exampleWrapper}>
      <PrimaryButton size={ButtonSize.Lg} onClick={() => {}} caption={'Apply'} />
    </div>
</div>`;

export { primaryButtonsSizedFullCode, primarySizedButtonsCode };