import { makeAutoObservable, runInAction } from 'mobx';
import UserFormModel from '../Shared/Models/UserFormModel';
import { ISelectItem } from '../Shared/Interfaces';
import { userAuthApiService, usersApiService } from '../ApiServices';

class UsersStore {
  public isPending = false;

  public isShowForm = false;

  public userIdForUpdatePassword = '';

  public userIdForRestore = '';

  public userIdToRemove = '';

  public user: UserFormModel = new UserFormModel();

  public organizations: ISelectItem[] = [
    { value: '01', label: 'Rubius' },
    { value: '02', label: 'Spar' },
    { value: '03', label: 'Триград' },
  ];

  public skills: ISelectItem[] = [
    { value: '01', label: 'JS' },
    { value: '02', label: 'TS' },
    { value: '03', label: 'React' },
    { value: '04', label: 'Angular' },
    { value: '05', label: 'Vue' },
    { value: '06', label: 'C#' },
  ];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public async submit() {
    this.isPending = true;
    try {
      const dto = this.user.getPostDto();
      if (!dto) return;

      await usersApiService.createUser(dto);
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        debugger
        this.isPending = false;
      });
    }
  }

  public closeForm() {
    this.isShowForm = false;
    this.user.clear();
  }

  public closeRemoveDialog() {
    this.userIdToRemove = '';
  }

  public closeRestoreDialog() {
    this.userIdForRestore = '';
  }

  public showRemoveDialog(cloudId: string) {
    this.userIdToRemove = cloudId;
  }

  public showRestoreDialog(userId: string) {
    this.userIdForRestore = userId;
  }

  public copyPassword() {
    navigator.clipboard.writeText(this.user.password);
  }

  public deInit() {}
}

export default new UsersStore();
