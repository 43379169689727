import React from 'react';

import { Select } from '@mui/material';
import { InputLabel } from '@mui/material';
import { MenuItem } from '@mui/material';

import { Spinner } from '../../GropuingGroup';
import { SizeSpinner } from '../../GropuingGroup/Spinner';
import { ISelectItem } from '../../../Shared/Interfaces';

import useStyles from './styles';

interface IProps {
  className?: string;
  error?: boolean;
  itemsWrapperClass?: string;
  disabled?: boolean;
  height?: number;
  label?: string;
  isLoading?: boolean;
  items: ISelectItem[];
  value: string | null;
  onChange(selected: string): void;
}

const SelectWrapper = (props: IProps) => {
  const { className, error, itemsWrapperClass, disabled, height, isLoading, items, label, onChange, value } = props;

  const c = useStyles({ height });

  const onChangeValue = (event: any) => {
    onChange(event.target.value as string);
  };

  return (
    <div className={`${className} ${c.wrapper}`}>
      <Spinner isLoading={!!isLoading} size={SizeSpinner.Sm} />
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        className={c.selectWrapper}
        error={error}
        disabled={disabled}
        variant="outlined"
        labelId="select-label"
        value={value}
        onChange={onChangeValue}>
        {items.map(item => (
          <MenuItem className={itemsWrapperClass || c.listItems} key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectWrapper;
