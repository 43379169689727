import React from 'react';
import { observer } from 'mobx-react';

import { InputWrapper, Spinner } from 'Elements/GropuingGroup';
import { TextInput } from 'Elements/InputsGroup/TextInput';
import { LabelPosition } from 'Elements/GropuingGroup/InputWrapper';

import { TextB1 } from 'Elements/TextWrappers';

import { PrimaryButton } from 'Elements/Buttons';

import usersStore from 'Stores/usersStore';

import ImageUploader from 'Elements/ImageUploader';
import { ImageFormats } from 'Elements/ImageUploader/ImageUploader';

import { Select } from 'Elements/Selects/Select';
import { SelectMultiple } from 'Elements/Selects/SelectMultiple';
import { CheckBox } from 'Elements/CheckBoxes/CheckBox';

import { SizeSpinner } from 'Elements/GropuingGroup/Spinner';

import useStyles from './styles';

const UserForm = () => {
  const c = useStyles({});

  return (
    <div className={c.userFormWrapper}>
      <Spinner isLoading={usersStore.isPending} size={SizeSpinner.Md} />

      <div>
        {usersStore.isPending.toString()}
        <div className={c.contentWrapper}>
          <InputWrapper
            required={true}
            validationMessage={usersStore.user.surnameValidState}
            className={c.userFormRow}
            spaceBetweenSize={16}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Фамилия'} />}
            inputCell={
              <TextInput
                error={!!usersStore.user.surnameValidState}
                className={c.userFormInput}
                onChange={usersStore.user.onChangeSurname}
                value={usersStore.user.surname}
              />
            }
          />

          <InputWrapper
            required={true}
            validationMessage={usersStore.user.firstNameValidState}
            className={c.userFormRow}
            spaceBetweenSize={16}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Имя'} />}
            inputCell={
              <TextInput
                error={!!usersStore.user.firstNameValidState}
                className={c.userFormInput}
                onChange={usersStore.user.onChangeFirstName}
                value={usersStore.user.firstName}
              />
            }
          />

          <InputWrapper
            className={c.userFormRow}
            validationMessage={usersStore.user.patronymicValidState}
            spaceBetweenSize={16}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Отчество'} />}
            inputCell={
              <TextInput
                className={c.userFormInput}
                error={!!usersStore.user.patronymicValidState}
                onChange={usersStore.user.onChangePatronymic}
                value={usersStore.user.patronymic}
              />
            }
          />

          <InputWrapper
            required={true}
            validationMessage={usersStore.user.loginNameValidState}
            className={c.userFormRow}
            spaceBetweenSize={16}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Логин'} />}
            inputCell={
              <TextInput
                error={!!usersStore.user.loginNameValidState}
                className={c.userFormInput}
                onChange={usersStore.user.onChangeLogin}
                value={usersStore.user.login}
              />
            }
          />

          <InputWrapper
            required={true}
            validationMessage={usersStore.user.loginNameValidState}
            className={c.userFormRow}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Организация'} />}
            inputCell={
              <Select
                items={usersStore.organizations}
                value={usersStore.user.organizationId}
                onChange={usersStore.user.onChangeOrg}
              />
            }
          />

          <InputWrapper
            required={true}
            validationMessage={usersStore.user.loginNameValidState}
            className={c.userFormRow}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Навыки'} />}
            inputCell={
              <SelectMultiple
                items={usersStore.skills}
                values={usersStore.user.skills}
                onChange={usersStore.user.onChangeSkills}
              />
            }
          />

          <InputWrapper
            required={true}
            validationMessage={usersStore.user.loginNameValidState}
            className={c.userFormRow}
            labelPosition={LabelPosition.Left}
            labelCell={<TextB1 noWrap={true} text={'Фото'} />}
            inputCell={
              <ImageUploader
                title="Загрузить фото"
                format={ImageFormats.PNG}
                image={usersStore.user.image.preview}
                onRemoveImage={usersStore.user.onRemoveImage}
                onImageLoad={usersStore.user.onUploadImage}
              />
            }
          />
        </div>

        <InputWrapper
          required={true}
          validationMessage={usersStore.user.loginNameValidState}
          className={c.userFormRow}
          spaceBetweenSize={16}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 noWrap={true} text={'Проверен'} />}
          inputCell={<CheckBox onChange={usersStore.user.onCheck} checked={usersStore.user.checked} />}
        />

        <div className={c.userFormActionRow}>
          <PrimaryButton onClick={usersStore.submit} caption={usersStore.user.id ? 'Сохранить' : 'Создать'} />
        </div>
      </div>
    </div>
  );
};

export default observer(UserForm);
