import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { InputWrapperGroups } from './Components';

import useStyles from './styles';

export enum InputWrapperTypes {
  LabelTop = 'Текстовая метка сверху',
  LabelLeft = 'Текстовая метка слева',
  Customized = 'Кастомный вариант',
}

const InputWrappers = () => {
  const c = useStyles();

  const items = [InputWrapperTypes.LabelTop, InputWrapperTypes.LabelLeft, InputWrapperTypes.Customized];

  return (
    <ExamplesWrapper
      render={param => <InputWrapperGroups type={param} />}
      title={'Группа ввода (InputGroup)'}
      description={
        'Реализует элемент формы, сочетающий пару: поле ввода с текстовой меткой(label).' +
        ' Размещеине текстовой метки относительно элемента ввода управляется через параметр. Текстовая метка реализовано через TextWrapper.' +
        ' Поле ввода может быть текстовый ввод, числовой ввод, выпадающий список, живой поиск, чекбокс, и т.д.'
      }
      examples={items}
    />
  );
};

export default InputWrappers;
