import React from 'react';

import { ExamplesWrapper } from 'Modules/MainPage/Components';

import { CheckBoxesGroups } from './Components';

export enum CheckBoxExampleTypes {
  Basic = 'Basic',
  Icon = 'Icon',
  Color = 'Color',
  Size = 'Size',
  Label = 'Label',
  Indeterminate = 'Indeterminate',
  Group = 'Group',
}

const CheckBoxes: React.FC = () => {
  const items = [
    CheckBoxExampleTypes.Basic,
    CheckBoxExampleTypes.Icon,
    CheckBoxExampleTypes.Label,
    CheckBoxExampleTypes.Indeterminate,
    CheckBoxExampleTypes.Size,
    CheckBoxExampleTypes.Color,
  ];

  /*  const getCodeExample = (type: CheckBoxExampleTypes, isFull?: boolean): string => {
    switch (type) {
      case CheckBoxExampleTypes.Block: {
        return isFull ? primaryButtonsFullCode : primaryButtonsCode;
      }

      default:
        return '';
    }
  }; */

  return (
    <>
      <ExamplesWrapper
        title={'Чекбоксы'}
        description={
          'В данном разделе представлены Чекбоксы разных вариантов: базовые, разных размеров, иконочные, кастомизированные'
        }
        render={param => <CheckBoxesGroups type={param} />}
        examples={items}
      />
    </>
  );
};

export default CheckBoxes;
