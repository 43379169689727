import React, { useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { CommonTile, Form, InputWrapper, Spinner } from 'Elements/GropuingGroup';
import { LabelPosition } from 'Elements/GropuingGroup/InputWrapper';
import { SizeSpinner } from 'Elements/GropuingGroup/Spinner';

import { TextB1 } from 'Elements/TextWrappers';
import { TextInput } from 'Elements/InputsGroup/TextInput';

import { PrimaryButton, IconButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from './styles';

interface IProps {
  authData: IAuthData;
  authTitle?: string;
  className?: string;
  isPending?: boolean;
  isShowRemember?: boolean;
  loginCaption?: string;
  listForSelectLogin?: any[];
  passwordCaption?: string;
  submitButtonCaption?: string;

  onChangeLogin(login: string): void;
  onChangePassword(password: string): void;
  onCancel?(): void;
  onSubmit(): void;
}

export interface IAuthData {
  login: string;
  password: string;
}

const AuthModule = (props: IProps) => {
  const c = useStyles();
  const {
    authData,
    authTitle,
    className,
    isPending,
    isShowRemember,
    loginCaption,
    listForSelectLogin,
    passwordCaption,
    submitButtonCaption,
    onChangeLogin,
    onChangePassword,
    onCancel,
    onSubmit,
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);

  const onChangePasswordVisibility = () => {
    setIsShowPassword(!isShowPassword);
  };

  const passwordVisibleControl = (
    <IconButton onClick={onChangePasswordVisibility} icon={isShowPassword ? <Visibility /> : <VisibilityOff />} />
  );

  return (
    <CommonTile className={c.authForWrapper}>
      <Spinner isLoading={isPending || false} size={SizeSpinner.Md} />
      <Form onSubmit={onSubmit} onCancel={onCancel} className={className || c.authForm}>
        <TextB1 noWrap={true} className={c.authTitle} text={authTitle || 'Введите логин и пароль'} />

        {listForSelectLogin ? (
          <div>select list</div>
        ) : (
          <InputWrapper
            className={c.inputItem}
            labelPosition={LabelPosition.TopLeft}
            spaceBetweenSize={16}
            labelCell={<TextB1 text={loginCaption || 'Логин'} />}
            inputCell={<TextInput onChange={onChangeLogin} value={authData.login} />}
          />
        )}

        <InputWrapper
          className={c.inputItem}
          labelPosition={LabelPosition.TopLeft}
          spaceBetweenSize={16}
          labelCell={<TextB1 text={passwordCaption || 'Пароль'} />}
          inputCell={
            <TextInput
              password={isShowPassword}
              onChange={onChangePassword}
              value={authData.password}
              endAdornment={passwordVisibleControl}
            />
          }
        />

        <div className={c.authFormActions}>
          <PrimaryButton
            size={ButtonSize.Md}
            buttonClassName={c.loginButton}
            onClick={onSubmit}
            caption={submitButtonCaption || 'Войти'}
          />
        </div>
      </Form>
    </CommonTile>
  );
};

export default AuthModule;
