import React from 'react';

import { ITextWrapperProps } from '../types';

import useStyles from './styles';

const TextBtn = (props: ITextWrapperProps) => {
  const { className, children, color, id, ref, fontWeight, noWrap, text, size } = props;
  const c = useStyles({ fontWeight, color, noWrap: noWrap || true, size });

  return (
    <span className={className ? `${className} ${c.textButton}` : c.textButton} id={id} ref={ref}>
      {children ? children : text}
    </span>
  );
};

export default TextBtn;
