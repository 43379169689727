import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(indents.four),
  },
  colorCheckBox: {
    marginTop: theme.spacing(indents.four),
  },
}));

export default useStyles;
