import React from 'react';

import clsx from 'clsx';

import { TextB1 } from 'Elements/TextWrappers';

import { LabelPosition } from './types';

import useStyles from './styles';

interface IProps {
  className?: string;
  inputCell?: JSX.Element;
  labelCell?: JSX.Element;
  labelPosition?: LabelPosition;
  labelWrapperClassName?: string;
  required?: boolean;
  spaceBetweenSize?: number;
  validationMessage?: string;
}

const InputWrapper = (props: IProps) => {
  const {
    className,
    inputCell,
    labelCell,
    validationMessage,
    labelPosition,
    labelWrapperClassName,
    required,
    spaceBetweenSize,
  } = props;
  const c = useStyles({ spaceBetweenSize });

  let styledByPosition = '';
  let indentStyle = '';

  switch (labelPosition) {
    case LabelPosition.Left: {
      styledByPosition = c.inputElemsInRow;
      indentStyle = c.inputLabelOnLeft;
      break;
    }
    case LabelPosition.TopLeft: {
      styledByPosition = c.inputElemsInColumnSlipLeft;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    case LabelPosition.TopCenter: {
      styledByPosition = c.inputElemsInColumnCentered;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    case LabelPosition.TopRight: {
      styledByPosition = c.inputElemsInColumnSlipRight;
      indentStyle = c.inputLabelOnAbove;
      break;
    }
    default: {
      styledByPosition = c.inputElemsInRow;
      indentStyle = c.inputLabelOnLeft;
    }
  }

  const label = (
    <div className={labelWrapperClassName || indentStyle}>
      {labelCell} {required && <span className={c.requiredSign}>*</span>}
    </div>
  );

  return (
    <div className={className ? clsx(styledByPosition, className) : styledByPosition}>
      {labelPosition !== LabelPosition.Right && label}
      <div className={c.controlWrapper}>
        {validationMessage && <TextB1 className={c.validationNotice} text={validationMessage} />}
        {inputCell}
      </div>
      {labelPosition === LabelPosition.Right && label}
    </div>
  );
};

export default InputWrapper;
