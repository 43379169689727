import { MouseEventHandler } from 'react';

export interface IIconButtonProps {
  className?: string;
  color?: string;
  disabled?: boolean;
  hint?: string;
  icon: JSX.Element;
  onClick: MouseEventHandler;
  size?: IconButtonSize;
}

export enum IconButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
