import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { TextB1 } from 'Elements/TextWrappers';
import { IconButton } from 'Elements/Buttons';

import useStyles from './styles';

const Header = () => {
  const c = useStyles();

  const [onMouseOverGH, setOnMouseOverGH] = useState(false);

  return (
    <div className={c.header}>
      <div className={c.logoWrapper}>
        <img className={c.rubiusLogo} src={'images/rubius-logo.png'} alt={''} />
        <TextB1 className={c.systemName} color={'white'} text={'Rubius React Elements'} />
      </div>

      <div className={c.headerLinksWrapper}>
        <div onMouseOver={() => setOnMouseOverGH(true)} onMouseLeave={() => setOnMouseOverGH(false)}>
          <IconButton
            className={c.githubBtn}
            icon={<img className={c.githubIcn} src={onMouseOverGH ? 'github-hovered.svg' : 'github.svg'} />}
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
