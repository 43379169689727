import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { TreeView } from '@mui/lab';
import { TreeItem } from '@mui/lab';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';

import { TextInput, VariantInput } from 'Elements/InputsGroup/TextInput';
import { IconButton } from 'Elements/Buttons';

import { ElementNames } from 'Shared/Constatnts/constants';

import { appStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  selected: string;
  onElementClick(elementName: ElementNames): void;
}

const ElementsTree = (props: IProps) => {
  const c = useStyles();
  const { selected, onElementClick } = props;

  const [expanded, setExpanded] = useState<string[]>(
    appStore.filteredElemsTree.length > 0 ? [appStore.filteredElemsTree[0].name] : []
  );

  useEffect(() => {
    if (appStore.filteredElemsTree.length < 1) return;

    if (!appStore.filterPattern || appStore.filteredElemsTree.length < 1) {
      setExpanded([appStore.filteredElemsTree[0].name]);
      return;
    }

    setExpanded(appStore.nodeWhichContainsFilterPattern);
  }, [appStore.filteredElemsTree.length]);

  const onNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  const dropPattern = () => {
    appStore.setNavigationFilterPattern('');
  };

  return (
    <div className={c.elementsTreeWrapper}>
      <div className={c.searchElemsWrapper}>
        <TextInput
          placeholder={'Найди меня !'}
          variant={VariantInput.Outlined}
          value={appStore.filterPattern}
          endAdornment={<IconButton icon={appStore.filterPattern ? <Close /> : <Search />} onClick={dropPattern} />}
          onChange={appStore.setNavigationFilterPattern}
        />
      </div>

      <div className={c.elementsTree}>
        {appStore.filteredElemsTree.map(group => (
          <TreeView
            key={group.name}
            className={c.elemsGroup}
            defaultCollapseIcon={<KeyboardArrowDown />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            selected={expanded}
            onNodeToggle={onNodeToggle}>
            <TreeItem className={c.elemsGroupItem} nodeId={group.name} label={group.name}>
              {group.elements.map(item => {
                const handleClick = () => {
                  onElementClick(item.nodeId);
                };

                return (
                  <div key={item.nodeId} className={c.treeItemRow}>
                    {selected === item.nodeId && (
                      <div className={c.treeItemRowArrow}>
                        <ArrowRight />
                      </div>
                    )}

                    <TreeItem
                      className={item.isExist ? c.elemsGroupItem : c.elementIsNotExist}
                      nodeId={item.nodeId.toString()}
                      label={item.label}
                      onClick={handleClick}
                    />
                  </div>
                );
              })}
            </TreeItem>
          </TreeView>
        ))}
      </div>
    </div>
  );
};

export default observer(ElementsTree);
