import { makeStyles } from '@mui/styles';

import { borderColors, indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  userFormWrapper: {
    padding: indents.thirtyTwo,
    width: 400,
    border: `solid ${borderColors.lightGrey} 1px`,
    borderRadius: 16,
  },
  contentWrapper: {

  },
  userFormRow: {
    marginTop: indents.thirtyTwo,
  },
  rowBtn: {
    minWidth: 40,
    maxHeight: 40,
    marginLeft: indents.eight,
  },
  userFormInput: {
    width: '100%',
  },
  userFormActionRow: {
    marginTop: indents.thirtyTwo,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
