export enum ButtonSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}

export enum TypeButton {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum VariantButton {
  Outlined = 'outlined',
  Contained = 'contained',
}