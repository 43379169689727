import { makeStyles } from '@mui/styles';

import { borderColors, borderRadius } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  errorIcon: {
    color: 'red',
  },
  inputWrapper: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: borderColors.lightBlue,
      '&:focused': {
        borderColor: borderColors.mediumBlue,
      },
      '&:hover': {
        borderColor: borderColors.darkBlue,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColors.lightBlue,
      },
      '&:hover fieldset': {
        borderColor: borderColors.mediumBlue,
      },
      '&.Mui-focused fieldset': {
        borderColor: borderColors.darkBlue,
      },
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      borderRadius: borderRadius.medium,
      height: '40px',
      minWidth: '40px',
    },
  },
}));

export default useStyles;
