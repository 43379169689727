import React from 'react';

import clsx from 'clsx';

import useStyles from './styles';

interface IProps {
  className?: string;
  children: React.ReactNode;
  pending?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const Form = (props: IProps) => {
  const c = useStyles();
  const { className, children, pending, onCancel, onSubmit } = props;

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && onSubmit) onSubmit();
    if (e.code === 'Escape' && onCancel) onCancel();
  };

  return (
    <div className={className ? clsx(c.formWrapper, className) : c.formWrapper} onKeyUp={onKeyUp}>
      {children}
    </div>
  );
};

export default Form;
