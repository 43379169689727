import React from 'react';

import { Select } from '@mui/material';
import { InputLabel } from '@mui/material';
import { MenuItem } from '@mui/material';

import { Spinner } from '../../GropuingGroup';
import { SizeSpinner } from '../../GropuingGroup/Spinner';

import { ISelectItem } from 'Shared/Interfaces';

import useStyles from './styles';

interface IProps {
  className?: string;
  label?: string;
  isLoading?: boolean;
  items: ISelectItem[];
  value: string;
  onChange(selected: string): void;
}

const SelectWrapper = (props: IProps) => {
  const { className, isLoading, items, label, onChange, value } = props;

  const c = useStyles();

  const onChangeValue = (event: any) => {
    onChange(event.target.value as string);
  };

  return (
    <div className={`${className} ${c.wrapper}`}>
      <Spinner isLoading={!!isLoading} size={SizeSpinner.Sm} />
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        className={`${c.selectWrapper}`}
        variant="outlined"
        labelId="select-label"
        id="simple-select"
        value={value}
        onChange={onChangeValue}>
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            <div className={c.rowItem}>{item.label}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectWrapper;
