import { makeStyles, DefaultTheme } from '@mui/styles';

import { fontColors, fontsDefinition } from 'themes/materialTheme';

import { IStyleProps } from '../types';

const useStyles = makeStyles<DefaultTheme, IStyleProps>(style => ({
  textB2: props => ({
    textTransform: 'none',
    whiteSpace: props.noWrap ? 'nowrap' : 'normal',
    color: props.color || fontColors.darkBlue,
    fontFamily: fontsDefinition.body2.fontName,
    fontSize: fontsDefinition.body2.size,
    fontStyle: 'normal',
    fontWeight: fontsDefinition.body2.weight,
    lineHeight: '100%',
    letterSpacing: '0.5px',
  }),
}));

export default useStyles;
