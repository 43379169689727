import React from 'react';

import { TextWrapperTypes } from '../TextWrappers';

import { TextB1, TextB2, TextBtn, TextC, TextS, TextM, TextH5, TextS2, FontWeight } from 'Elements/TextWrappers';

import { fontColors, specialColors } from 'themes/materialTheme';

import useStyles from './styles';

interface IProps {
  type: TextWrapperTypes;
}

const TextWrapperGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  const body1Examples = (
    <>
      <TextB1 color={fontColors.mediumGrey} className={c.textWrapper} text={'Body 1 text'} />
      <TextB1 color={specialColors.mediumRed} className={c.textWrapper} text={'Body 1 text'} />
      <TextB1 className={c.textWrapper} text={'Body 1 text'} />
      <TextB1 fontWeight={FontWeight.Bold} className={c.textWrapper} text={'Body 1 text'} />
    </>
  );

  const body2Examples = (
    <>
      <TextB2 color={specialColors.mediumGreen} className={c.textWrapper} text={'Body 2 text'} />
      <TextB2 fontWeight={FontWeight.Bold} className={c.textWrapper} text={'Body 2 text'} />
    </>
  );

  const buttonTextExamples = (
    <>
      <TextBtn color={specialColors.mediumGreen} className={c.textWrapper} text={'PrimaryButton text'} />
      <TextBtn fontWeight={FontWeight.Bold} className={c.textWrapper} text={'PrimaryButton text'} />
    </>
  );

  const captionTextExamples = (
    <>
      <TextC color={fontColors.mediumGrey} className={c.textWrapper} text={'Caption text'} />
      <TextC fontWeight={FontWeight.Bold} className={c.textWrapper} text={'Caption text'} />
    </>
  );

  const h5TextExamples = (
    <>
      <TextH5 color={fontColors.mediumGrey} className={c.textWrapper} text={'H5 text'} />
      <TextH5 fontWeight={FontWeight.Bold} className={c.textWrapper} text={'H5 text'} />
    </>
  );

  const menuTextExamples = (
    <>
      <TextM color={fontColors.mediumGrey} className={c.textWrapper} text={'Menu text'} />
      <TextM fontWeight={FontWeight.Bold} className={c.textWrapper} text={'Menu text'} />
    </>
  );

  const subtitleTextExamples = (
    <>
      <TextS color={fontColors.mediumGrey} className={c.textWrapper} text={' Subtitle 1 text'} />
      <TextS fontWeight={FontWeight.Bold} className={c.textWrapper} text={' Subtitle 1 text'} />
    </>
  );

  const subtitle2TextExamples = (
    <>
      <TextS2 color={fontColors.mediumGrey} className={c.textWrapper} text={'Subtitle 2 text'} />
      <TextS2 fontWeight={FontWeight.Bold} className={c.textWrapper} text={'Subtitle 2 text'} />
    </>
  );

  return (
    <>
      {type === TextWrapperTypes.Body1 && body1Examples}
      {type === TextWrapperTypes.Body2 && body2Examples}
      {type === TextWrapperTypes.ButtonText && buttonTextExamples}
      {type === TextWrapperTypes.Caption && captionTextExamples}
      {type === TextWrapperTypes.H5Text && h5TextExamples}
      {type === TextWrapperTypes.MenuText && menuTextExamples}
      {type === TextWrapperTypes.Subtitle && subtitleTextExamples}
      {type === TextWrapperTypes.Subtitle2 && subtitle2TextExamples}
    </>
  );
};

export default TextWrapperGroups;
