import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { ButtonsGroups } from './Components';

import { primaryButtonsCode, primaryButtonsFullCode } from './Components/Examples/PrimaryButtons/code';
import {
  primaryButtonsSizedFullCode,
  primarySizedButtonsCode,
} from './Components/Examples/PrimarySizedButtons/code';

import useStyles from './styles';

export enum ButtonExampleTypes {
  Block = 'PrimaryButton, SecondaryButton',
  PrimarySized = 'PrimaryButton разных размеров',
  SecondarySized = 'SecondaryButton разных размеров',
  ButtonColored = 'P/S Buttons разных размеров ',
  IconInside = 'PrimaryButton c иконками',
  IconWrapped = 'IconButton',
  IconWrappedSized = 'IconButton разного размера',
  IconWrappedColored = 'IconButton разных цветов',
  ButtonsWithProgress = 'PrimaryButton с прогрессом',
}

const Buttons = () => {
  const c = useStyles();

  const items = [
    ButtonExampleTypes.Block,
    ButtonExampleTypes.PrimarySized,
    ButtonExampleTypes.SecondarySized,
    ButtonExampleTypes.ButtonColored,
    ButtonExampleTypes.IconInside,
    ButtonExampleTypes.IconWrapped,
    ButtonExampleTypes.IconWrappedSized,
    ButtonExampleTypes.IconWrappedColored,
    ButtonExampleTypes.ButtonsWithProgress,
  ];

  const getCodeExample = (type: ButtonExampleTypes, isFull?: boolean): string => {
    switch (type) {
      case ButtonExampleTypes.Block: {
        return isFull ? primaryButtonsFullCode : primaryButtonsCode;
      }

      case ButtonExampleTypes.PrimarySized: {
        return isFull ? primaryButtonsSizedFullCode : primarySizedButtonsCode;
      }

      default:
        return '';
    }
  };

  return (
    <>
      <ExamplesWrapper
        title={'Кнопки'}
        description={
          'В данном разделе представлены кнопки разных вариантов: базовые, разных размеров, иконочные, кастомизированные'
        }
        render={param => <ButtonsGroups type={param} />}
        getCode={getCodeExample}
        examples={items}
      />
    </>
  );
};

export default Buttons;
