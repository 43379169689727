import { action, makeObservable, observable, runInAction } from 'mobx';

import { userAuthApiService } from '../ApiServices';

class UserAuthStore {
  public isPending: boolean = false;

  public login: string = '';

  public password: string = '';

  constructor() {
    makeObservable(this, {
      isPending: observable,
      login: observable,
      password: observable,

      onChangeLogin: action.bound,
      onChangePassword: action.bound,
      onSubmit: action.bound,
    });
  }

  public onChangeLogin(login: string) {
    this.login = login;
  }

  public onChangePassword(password: string) {
    this.password = password;
  }

  public async onSubmit() {
    try {
      this.isPending = true;
      await userAuthApiService.authUser({ login: this.login, password: this.password });
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }
}

export default new UserAuthStore();
