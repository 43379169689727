import React, { ChangeEvent, MouseEvent, ReactNode, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { EnhancedTableHeader } from './components';
import TablePagination, { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import Popover, { PopoverPosition } from '@mui/material/Popover';

import { Spinner } from '../GropuingGroup';

import { ITableColumn } from './index';

import useStyles from './styles';
import { SizeSpinner } from '../GropuingGroup/Spinner';

const DEFAULT_PAGINATION_OPTIONS = [5, 10, 15, 20];

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  return `${from}-${to === -1 ? count : to} из ${count !== -1 ? count : `больше ${to}`}`;
};

interface IProps {
  columns: ITableColumn[];
  customClassCell?: string;
  data: any[];
  dataKey: string;
  totalCount?: number;
  rowsPerPage?: number;
  page?: number;
  rowsPerPageOptions?: number[];
  loading?: boolean;
  hover?: boolean;
  selectedItemKey?: any;
  showPopoverOnSelect?: boolean;
  popoverContent?: ReactNode;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onRowClick?: (row: any, e: MouseEvent<Element>) => void;
  onPopoverClose?: () => void;
  onSort?: (order: 'asc' | 'desc' | undefined, orderBy: string | undefined) => void;
}

const CustomTable = (props: IProps) => {
  const {
    columns,
    customClassCell,
    data,
    dataKey,
    totalCount,
    rowsPerPage,
    page,
    rowsPerPageOptions,
    loading,
    hover,
    selectedItemKey,
    showPopoverOnSelect,
    popoverContent,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick,
    onPopoverClose,
    onSort,
  } = props;

  const c = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>();

  const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
  const [orderBy, setOrderBy] = useState<string | undefined>();

  const renderRow = (row: any, cols: ITableColumn[]) => {
    return cols.map(column => {
      return (
        <TableCell
          variant="body"
          key={column.key}
          align={column.align}
          className={customClassCell ? customClassCell : ''}
          style={{ maxWidth: column.maxWidth, width: column.width }}>
          {column.cell ? column.cell(row) : row[column.key]}
        </TableCell>
      );
    });
  };

  const renderRows = (tableRows: any[]) => {
    return tableRows.map(row => {
      const handleClick = (e: MouseEvent<Element>) => {
        if (!onRowClick) return;

        if (showPopoverOnSelect) {
          setAnchorPosition({ top: e.clientY, left: e.clientX });
          setOpen(true);
        }

        onRowClick(row, e);
      };

      return (
        <TableRow selected={selectedItemKey === row[dataKey]} hover={hover} key={row[dataKey]} onClick={handleClick}>
          {renderRow(row, columns)}
        </TableRow>
      );
    });
  };

  const handlePageChange = (e: MouseEvent<HTMLButtonElement> | null, value: number) => {
    if (!onChangePage) return;

    onChangePage(value);
  };

  const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChangeRowsPerPage) return;

    onChangeRowsPerPage(Number.parseInt(e.target.value, 10));
  };

  const handlePopoverClose = () => {
    setOpen(false);

    if (onPopoverClose) {
      onPopoverClose();
    }
  };

  const handleSort = (event: MouseEvent<unknown>, property: string | undefined) => {
    setOrderBy(property);

    let newOrder;

    if (property) {
      if (orderBy === property) {
        newOrder = order === 'asc' ? 'desc' : 'asc';
      } else {
        newOrder = 'asc';
      }
    }

    setOrder(newOrder as any);

    if (onSort) {
      onSort(newOrder as any, property);
    }
  };

  const renderPagination = () => {
    if (totalCount === undefined || page === undefined || rowsPerPage === undefined) return null;

    return null; // TODO Varalamov: smth wrong with pagination props
    // return (
    //   <TablePagination
    //     labelRowsPerPage="Строк на странице:"
    //     className={c.pagination}
    //     count={totalCount}
    //     page={page}
    //     rowsPerPage={rowsPerPage}
    //     rowsPerPageOptions={rowsPerPageOptions || DEFAULT_PAGINATION_OPTIONS}
    //     onChangePage={handlePageChange}
    //     onChangeRowsPerPage={handleChangeRowsPerPage}
    //     labelDisplayedRows={labelDisplayedRows}
    //   />
    // );
  };

  return (
    <div className={c.wrapper}>
      <TableContainer>
        <Table stickyHeader={true}>
          <EnhancedTableHeader
            className={c.head}
            columns={columns}
            order={order}
            orderBy={orderBy}
            onSort={handleSort}
          />

          <TableBody>{renderRows(data)}</TableBody>
        </Table>
      </TableContainer>

      <Spinner isLoading={!!loading} zIndex={3} size={SizeSpinner.Md} />

      {showPopoverOnSelect && (
        <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          onClose={handlePopoverClose}>
          {popoverContent}
        </Popover>
      )}

      {renderPagination()}
    </div>
  );
};

export default CustomTable;
