import { makeStyles } from '@mui/styles';

import { borderRadius, indents, borderColors, fontColors } from 'themes/materialTheme';

interface IProps {
  height?: number;
  isLightBg?: boolean;
}

const useStyles = makeStyles<null, IProps>({
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  selectedItemsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectedItem: {
    backgroundColor: borderColors.lightBlue,
    borderRadius: borderRadius.medium,
    marginRight: indents.four,
    marginTop: indents.four,
    marginBottom: indents.four,
    padding: indents.four,
    paddingLeft: indents.eight,
    paddingRight: indents.eight,
  },
  selectedItemName: {
    color: fontColors.white,
  },
  selectWrapper: props => ({
    textAlign: 'left',
    height: 'auto',
    minHeight: 40,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      maxHeight: '200px',
      overflow: 'auto',
      padding: `${indents.four}px ${indents.eight}px`,
    },
    '& fieldset': {
      borderColor: borderColors.mediumBlue,
      borderRadius: borderRadius.medium,
    },
    '&:hover fieldset': {
      borderColor: borderColors.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: borderColors.darkBlue,
    },
  }),
  items: {
    backgroundColor: borderColors.darkBlue,
  },
  itemIcon: {
    width: '14px !important',
    height: '14px !important',
    marginLeft: '4px !important',
  },
  removeItemBtn: {
    width: '14px !important',
    height: '14px !important',
    color: fontColors.white,
  },
});

export default useStyles;
