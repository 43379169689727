import React from 'react';

import Apps from '@mui/icons-material/Apps';
import BackupIcon from '@mui/icons-material/Backup';
import Battery20Icon from '@mui/icons-material/Battery20';

import { PrimaryButton, SecondaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import { specialColors } from 'themes/materialTheme';

import useStyles from '../../styles';

const ColoredButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <div>
        <div className={c.buttonsRow}>
          <div className={c.exampleWrapper}>
            <PrimaryButton
              color={{ medium: specialColors.mediumOrange, dark: specialColors.darkOrange }}
              size={ButtonSize.Sm}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <PrimaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <PrimaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              startIcon={<Apps className={c.rubius} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <PrimaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              endIcon={<BackupIcon className={''} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <PrimaryButton
              color={{ medium: specialColors.mediumRed, dark: specialColors.darkRed }}
              size={ButtonSize.Sm}
              startIcon={<Battery20Icon className={''} />}
              endIcon={<BackupIcon className={''} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>
        </div>

        <div className={c.buttonsRow}>
          <div className={c.exampleWrapper}>
            <SecondaryButton
              color={{ medium: specialColors.mediumOrange, dark: specialColors.darkOrange }}
              size={ButtonSize.Sm}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>
          <div className={c.exampleWrapper}>
            <SecondaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <SecondaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              startIcon={<Apps className={c.rubius} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <SecondaryButton
              color={{ medium: specialColors.mediumGreen, dark: specialColors.darkGreen }}
              size={ButtonSize.Sm}
              endIcon={<BackupIcon style={{ color: specialColors.mediumGreen }} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>

          <div className={c.exampleWrapper}>
            <SecondaryButton
              color={{ medium: specialColors.mediumRed, dark: specialColors.darkRed }}
              size={ButtonSize.Sm}
              startIcon={<Battery20Icon style={{ color: specialColors.mediumRed }} />}
              endIcon={<BackupIcon style={{ color: specialColors.mediumRed }} />}
              onClick={() => {}}
              caption={'Сохранить'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoredButtons;
