import React from 'react';
import { CheckBoxExampleTypes } from '../CheckBoxes';

import { CheckBox } from 'Elements/CheckBoxes/CheckBox';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import useStyles from './styles';

interface IProps {
  type: CheckBoxExampleTypes;
}

const CheckBoxesGroups: React.FC<IProps> = props => {
  const c = useStyles();

  const { type } = props;

  const baseExamples = (
    <div className={c.rowWrapper}>
      <CheckBox />
    </div>
  );

  const iconExamples = (
    <div className={c.rowWrapper}>
      <CheckBox iconChecked={<CircleOutlinedIcon />} iconUnChecked={<CheckCircleOutlineOutlinedIcon />} />
    </div>
  );

  const labelExamples = (
    <div className={c.rowWrapper}>
      <CheckBox label="top" labelPlacement="top" />
      <CheckBox label="start" labelPlacement="start" />
      <CheckBox label="bottom" labelPlacement="bottom" />
      <CheckBox label="end" labelPlacement="end" />
    </div>
  );

  const iconIndeterminate = (
    <div className={c.rowWrapper}>
      <CheckBox checked={false} />
      <CheckBox checked={null} />
      <CheckBox checked={true} />
    </div>
  );

  const iconSize = (
    <div className={c.rowWrapper}>
      <CheckBox size="small" />
      <CheckBox />
    </div>
  );

  const iconColor = (
    <div className={c.rowWrapper}>
      <CheckBox labelPlacement="bottom" label="primary" />
      <CheckBox labelPlacement="bottom" label="secondary" color="secondary" />
      <CheckBox labelPlacement="bottom" label="success" color="success" />
      <CheckBox labelPlacement="bottom" label="default" color="default" />
      <CheckBox labelPlacement="bottom" label="custom" customColorChecked="#00ff00" />
    </div>
  );

  return (
    <>
      {type === CheckBoxExampleTypes.Basic && baseExamples}
      {type === CheckBoxExampleTypes.Icon && iconExamples}
      {type === CheckBoxExampleTypes.Label && labelExamples}
      {type === CheckBoxExampleTypes.Indeterminate && iconIndeterminate}
      {type === CheckBoxExampleTypes.Size && iconSize}
      {type === CheckBoxExampleTypes.Color && iconColor}
    </>
  );
};

export default CheckBoxesGroups;
