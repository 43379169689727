import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  pagination: {
    marginTop: 'auto',
    '&.MuiTablePagination-root': {
      minHeight: '52px',
      overflow: 'hidden',
    },
  },
  head: {
    '& .MuiTableCell-head': {
      backgroundColor: '#F5F5F5',
      borderBottom: 'none',
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      '&:last-child': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
  },
}));

export default useStyles;
