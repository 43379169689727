import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    height: 'unset',
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(2),
    '& > button': {
      '&:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  active: {
    color: theme.palette.primary.light,
  },
}));

export default useStyles;
