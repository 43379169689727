import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { CodeEditorEditable } from 'react-code-editor-editable';

import { TextB2, TextS } from 'Elements/TextWrappers';

import ExampleActionsRow from './ExampleActionsRow';
import DisplayControl from './DisplayControl';

import { appStore } from 'Stores';

import 'highlight.js/styles/atom-one-dark.css';

import useStyles from './styles';

interface IProps {
  name: string;
  children: React.ReactNode;

  getCode(isFull?: boolean): string;
  dropToDefault?(): void;
}

const Example = (props: IProps) => {
  const { getCode, children, name, dropToDefault } = props;

  const c = useStyles({
    height: appStore.fullScreenParam.getHeight(name),
    width: appStore.fullScreenParam.getWidth(name),
  });

  const ref = useRef<HTMLDivElement>(null);

  const [elemPosition, setElemPosition] = useState(0);
  const [isExampleExpanded, setIsExampleExpanded] = useState(false);
  const [isShowOnlyExampleCode, setIsShowOnlyExampleCode] = useState(true);
  const [exampleCode, setExampleCode] = useState(getCode(!isShowOnlyExampleCode));

  useEffect(() => {
    setExampleCode(getCode(isShowOnlyExampleCode));
  }, [isShowOnlyExampleCode]);

  useEffect(() => {
    if (!appStore.currentScroll) return;
    if (!elemPosition) setElemPosition(ref.current?.getBoundingClientRect().top!);
    if (elemPosition + window.innerHeight > appStore.currentScroll && elemPosition - 600 < appStore.currentScroll) {
      appStore.setCurrentNavName(name);
    }
  }, [appStore.currentScroll]);

  const onCopyCode = (): boolean => {
    navigator.clipboard.writeText(exampleCode);

    return true;
  };

  useEffect(() => {
    if (isExampleExpanded) {
      appStore.fullScreenParam.pinExample(name);
    } else {
      appStore.fullScreenParam.dropDefault();
    }
  }, [isExampleExpanded]);

  return (
    <div className={isExampleExpanded ? c.exampleWrapperExpanded : c.exampleWrapper}>
      {useMemo(
        () => (
          <>
            <div className={c.exampleContent}>
              <div className={c.titleRow}>
                <TextS className={c.exampleTitle} text={`Пример: ${name}`} />
                {!isExampleExpanded && <TextB2 className={c.exampleDescription} text={'Example Description'} />}

                {isExampleExpanded && <DisplayControl />}
              </div>

              <div ref={ref} className={`${c.mainViewExample} ${isExampleExpanded ? c.fullScreenExample : ''}`}>
                <div className={c.exampleDisplay}>{children}</div>
              </div>

              <ExampleActionsRow
                isExpanded={isExampleExpanded}
                expandToggle={setIsExampleExpanded}
                isShowOnlyExampleCode={isShowOnlyExampleCode}
                exampleCodeToggle={setIsShowOnlyExampleCode}
                onCopyCode={onCopyCode}
                dropToDefault={dropToDefault!}
              />
            </div>

            {!isExampleExpanded && (
              <div className={c.codeWrapperOuter}>
                <div className={c.codeWrapperInner}>
                  <CodeEditorEditable
                    value={exampleCode}
                    setValue={() => {}}
                    width="100%"
                    height={'80%'}
                    language="TypeScript"
                    inlineNumbers={true}
                  />
                </div>
              </div>
            )}
          </>
        ),
        [isExampleExpanded, isShowOnlyExampleCode]
      )}
    </div>
  );
};

export default observer(Example);
