import React, { useState } from 'react';

import { PrimaryButton } from 'Elements/Buttons';
import { TextB1 } from 'Elements/TextWrappers';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import Battery20Icon from '@mui/icons-material/Battery20';
import BackupIcon from '@mui/icons-material/Backup';

import useStyles from '../../styles';

const ButtonsWithProgress = () => {
  const c = useStyles();

  const dialogs = [
    'Нажми на меня',
    'А ещё раз',
    'Это будет дизайн система, ага',
    'Ну хорош уже',
    'По хорошему ты не понимаешь',
    '',
    '>>:',
    '&^321 )-12^&&*',
  ];

  const [dialogMessage, setDialogMessage] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const onTestPendingState = () => {
    setIsPending(true);

    setTimeout(() => {
      setIsPending(false);

      if (dialogMessage < dialogs.length) setDialogMessage(dialogMessage + 1);
      else setDialogMessage(0);
    }, 500);
  };

  return (
    <div className={c.wrapper}>
      <div className={c.exampleWrapper}>
        <PrimaryButton
          isPending={true}
          size={ButtonSize.Md}
          startIcon={<Battery20Icon />}
          endIcon={<BackupIcon />}
          onClick={() => {}}
          caption={'Сохранить'}
        />
      </div>

      <div className={c.exampleWrapper}>
        <PrimaryButton
          isPending={true}
          size={ButtonSize.Md}
          startIcon={<Battery20Icon />}
          endIcon={<BackupIcon />}
          onClick={() => {}}
          caption={'Сохранить'}
        />
      </div>

      <div className={c.exampleWrapper} style={{ width: '50%' }}>
        {dialogMessage < dialogs.length ? (
          <PrimaryButton
            isPending={isPending}
            size={ButtonSize.Md}
            startIcon={<Battery20Icon />}
            endIcon={<BackupIcon />}
            onClick={onTestPendingState}
            caption={dialogs[dialogMessage]}
          />
        ) : (
          <TextB1 text={'Когда-то здесь была кнопка, но кто-то ее сломал, отчёт сформирован, принимаются меры'} />
        )}
      </div>
    </div>
  );
};

export default ButtonsWithProgress;
