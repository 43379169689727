import React from 'react';

import clsx from 'clsx';

import useStyles from './styles';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const CommonTile = (props: IProps) => {
  const { className, children } = props;
  const c = useStyles();

  return <div className={className ? clsx(c.commonTileWrapper, className) : c.commonTileWrapper}>{children}</div>;
};

export default CommonTile;
