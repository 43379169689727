import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#212121',
    height: '100px',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(indents.twentyFour),
  },
  rubiusLogo: {
    width: '80px',
  },
  systemName: {
    marginTop: theme.spacing(indents.eight),
  },
  themeSelector: {
    color: 'white',
    borderColor: 'white',
    width: '200px',
    marginLeft: 'auto',
    marginRight: theme.spacing(indents.thirtyTwo),
  },
  headerLinksWrapper: {
    marginRight: theme.spacing(indents.twentyFour),
    display: 'flex',
  },
  githubBtn: {
    marginRight: theme.spacing(indents.sixteen),
  },
  githubIcn: {
    width: '40px',
    height: '40px',
  },
}));

export default useStyles;
