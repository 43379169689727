import React from 'react';

import { IconButton } from 'Elements/Buttons';

import CameraAlt from '@mui/icons-material/CameraAlt';
import Check from '@mui/icons-material/Check';
import Create from '@mui/icons-material/Create';
import ControlPointDuplicate from '@mui/icons-material/ControlPointDuplicate';
import Close from '@mui/icons-material/Close';
import EventAvailable from '@mui/icons-material/EventAvailable';

import useStyles from '../../styles';

const IconButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <>
        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<CameraAlt />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<Check />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<Create />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<ControlPointDuplicate />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<EventAvailable />} onClick={() => {}} />
        </div>

        <div className={c.exampleWrapper}>
          <IconButton hint={'Description'} icon={<Close />} onClick={() => {}} />
        </div>
      </>
    </div>
  );
};

export default IconButtons;
