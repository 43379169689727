export const indents = {
  four: 4,
  eight: 8,
  sixteen: 16,
  twentyFour: 24,
  thirtyTwo: 32,
  forty: 40,
};

export const borderRadius = {
  small: 4,
  medium: 8,
  large: 16,
};

