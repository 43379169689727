import { action, makeObservable, observable, runInAction } from 'mobx';

import { tableDataApiService } from '../ApiServices';

import PaginationModel from '../Services/ListParams/PaginationParams';
import { TableExampleRow } from '../Shared/Models';
import { TableListParams } from '../Services/ListParams/TableListParams';

class TableStore {
  public isPending = false;

  public listParams = new TableListParams(
    (pageIndex: number, pageSize: number, sortingColumnName: string, isAscending: any) =>
      this.getData(pageIndex, pageSize, sortingColumnName, isAscending)
  );

  public data: TableExampleRow[] = [];

  constructor() {
    makeObservable(this, {
      isPending: observable,
      data: observable,

      getData: action.bound,
      setPending: action.bound,
    });
  }

  public setPending() {
    this.isPending = !this.isPending;
  }

  public async getData(pageIndex: number = 1, pageSize: number, sortingColumnName: string, isAscending: boolean) {
    try {
      this.isPending = true;
      const result = await tableDataApiService.getData(pageIndex, pageSize, sortingColumnName, isAscending);
      if (!result) return;

      const rows = result.page.map(dto => new TableExampleRow(dto));

      runInAction(() => {
        this.data = rows;
        this.listParams.pagination.setTotalCount(result.totalCount);
      });
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.isPending = false;
      });
    }
  }
}

export default new TableStore();
