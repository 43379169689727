import React, { useState, useRef, useEffect } from 'react';

import { ExampleNavigator, Example } from './Components';

import { TextH5, TextB1 } from 'Elements/TextWrappers';

import { appStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  title?: string;
  description?: string;
  examples: any[];
  descriptionRender?(): void;
  render(param: any): void;
  getCode?(example: any, isFull?: boolean): string;
  dropToDefault?(): void;
}

const ExamplesWrapper = (props: IProps) => {
  const c = useStyles();

  const { descriptionRender, dropToDefault, getCode, examples, title, description, render } = props;

  const selectedExampleRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<HTMLDivElement>(null);

  const [currentExample, setCurrentExample] = useState('');

  useEffect(() => {
    const scrollPosition = window.localStorage.getItem('scrollPosition');
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(1000, parseInt(scrollPosition, 10)), 1000);
    }
  }, []);

  const scrollToSelected = () => {
    if (!selectedExampleRef) return;

    selectedExampleRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToSelected();
  }, [currentExample]);

  const onScroll = () => {
    if (!scrollPositionRef.current?.scrollTop) return;

    appStore.setCurrentScroll(scrollPositionRef.current?.scrollTop);
  };

  const onNavigate = (id: string) => {
    setCurrentExample(id as any);
  };

  const plainText = <TextB1 className={c.examplesDescription} text={description || ' Description'} />;

  return (
    <div className={c.wrapper} id={'scroll'} onScroll={onScroll} ref={scrollPositionRef}>
      <div className={c.examplesWrapper}>
        <ExampleNavigator onSelect={onNavigate} navItems={examples} />
        <TextH5 className={c.examplesTitle} text={title || 'Title'} />

        {descriptionRender ? descriptionRender() : plainText}

        {examples.map((example, index) => {
          const getCodeStr = (isFull: boolean) => {
            return getCode ? getCode!(example, isFull) : '';
          };

          return (
            <div key={example} ref={currentExample === example ? selectedExampleRef : null} className={c.examplesGroup}>
              <Example name={example} getCode={getCodeStr} dropToDefault={dropToDefault}>
                <>{render(example)}</>
              </Example>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExamplesWrapper;
