import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import CustomTable, { ITableColumn } from 'Elements/CustomTable';
import TableAction from 'Elements/CustomTable/components/TableAction';

import { TableTypes } from '../Table';

import Create from '@mui/icons-material/Create';
import DeleteForever from '@mui/icons-material/DeleteForever';

import { tableStore } from 'Stores';

import useStyles from './styles';

interface IProps {
  type: TableTypes;
}

const TableGroups = (props: IProps) => {
  const c = useStyles();

  useEffect(() => {
    tableStore.listParams.onChangePage(0);
  }, []);

  const { type } = props;

  const columns: ITableColumn[] = [
    {
      key: 'name',
      label: 'Наименование',
      align: 'left',
      sortable: true,
    },
    {
      key: 'param1',
      label: 'Параметр 1',
      align: 'left',
      sortable: true,
    },

    {
      key: 'param2',
      label: 'Параметр 2',
      align: 'left',
      sortable: true,
    },
    {
      key: 'param3',
      label: 'Параметр 3',
      align: 'left',
      sortable: true,
    },
    {
      key: 'param4',
      label: 'Параметр 4',
      align: 'left',
      sortable: true,
    },
    {
      key: 'actions',
      label: '',
      align: 'left',
      cell: (row: any) => {
        const handleOnEdit = () => {};

        const handleOnRemove = () => {};

        return (
          <>
            <TableAction tooltip="Редактировать" onClick={handleOnEdit} icon={<Create />} />
            <TableAction tooltip="Удалить" onClick={handleOnRemove} icon={<DeleteForever />} />
          </>
        );
      },
    },
  ];

  const baseExamples = (
    <div className={c.tableWrapper}>
      <CustomTable
        loading={tableStore.isPending}
        columns={columns}
        data={tableStore.data}
        page={tableStore.listParams.pagination.pageIndex}
        rowsPerPage={tableStore.listParams.pagination.pageSize}
        totalCount={tableStore.listParams.pagination.totalCount}
        dataKey="id"
        hover={true}
        onChangePage={tableStore.listParams.onChangePage}
        onChangeRowsPerPage={tableStore.listParams.onChangePageSize}
        onSort={tableStore.listParams.onSort}
      />
    </div>
  );

  return <>{type === TableTypes.BaseTable && baseExamples}</>;
};

export default observer(TableGroups);
