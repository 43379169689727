import React, { useState } from 'react';

import { TextInput, TextInputSize } from 'Elements/InputsGroup/TextInput';

import InputWrapper, { LabelPosition } from 'Elements/GropuingGroup/InputWrapper';
import { TextB1 } from 'Elements/TextWrappers';

import { InputWrapperTypes } from '../InputWrappers';

import { fontColors } from 'themes/materialTheme';

import useStyles from './styles';

interface IProps {
  type: InputWrapperTypes;
}

const InputWrapperGroups = (props: IProps) => {
  const c = useStyles();

  const { type } = props;

  const [size, setSize] = useState('');

  const onChangeValue = (value: string) => {
    setSize(value);
  };

  const labelTop = (
    <>
      <InputWrapper
        className={c.inputWrapper}
        spaceBetweenSize={8}
        labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
        labelPosition={LabelPosition.TopLeft}
        inputCell={<TextInput onChange={onChangeValue} value={size} />}
      />

      <InputWrapper
        className={c.inputWrapper}
        spaceBetweenSize={8}
        labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
        labelPosition={LabelPosition.TopCenter}
        inputCell={<TextInput onChange={onChangeValue} value={size} />}
      />

      <InputWrapper
        className={c.inputWrapper}
        spaceBetweenSize={8}
        labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
        labelPosition={LabelPosition.TopRight}
        inputCell={<TextInput onChange={onChangeValue} value={size} />}
      />
    </>
  );

  const labelLeft = (
    <div className={c.leftSizeExample}>
      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>

      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          spaceBetweenSize={16}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>

      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          spaceBetweenSize={24}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>
    </div>
  );

  const customized = (
    <div className={c.leftSizeExample}>
      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>

      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          spaceBetweenSize={16}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 color={'#e3e3e3'} noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>

      <div>
        <InputWrapper
          className={c.inputWrapperLong}
          spaceBetweenSize={24}
          labelPosition={LabelPosition.Left}
          labelCell={<TextB1 color={fontColors.mediumGrey} noWrap={true} text={'Введите значение'} />}
          inputCell={<TextInput className={c.inputLong} onChange={onChangeValue} value={size} />}
        />
      </div>
    </div>
  );

  return (
    <>
      {type === InputWrapperTypes.LabelTop && labelTop}
      {type === InputWrapperTypes.LabelLeft && labelLeft}
      {type === InputWrapperTypes.Customized && customized}
    </>
  );
};

export default InputWrapperGroups;
