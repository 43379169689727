import React from 'react';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';
import { InputsGroups } from './Components';

import useStyles from './styles';

export enum InputExampleTypes {
  BaseExamples = 'Базовые примеры ввода',
  SizedExamples = 'Заданные размеры ввода',
  WithIconsAndButtons = 'Ввод с внутренним контроллом и иконокой',
}

const Inputs = () => {
  const c = useStyles();

  const items = [
    InputExampleTypes.BaseExamples,
    InputExampleTypes.SizedExamples,
    InputExampleTypes.WithIconsAndButtons,
  ];

  return (
    <ExamplesWrapper
      title={'Поле ввода текста (TextInput)'}
      description={
        'Компонент для ввода текстовой информации. Наиболее часто применение находит в формах заполнения данных, полях авторизации, поиска и т.д.'
      }
      render={param => <InputsGroups type={param} />}
      examples={items}
    />
  );
};

export default Inputs;
