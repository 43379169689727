import React from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { Theme } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import MainPage from './Modules/MainPage';

import './App.css';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={createTheme({ spacing: 1 })}>
        <Router>
          <Switch>
            <MainPage />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
