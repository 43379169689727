import React, { ReactElement } from 'react';

import { Checkbox, FormControlLabel, useTheme } from '@mui/material';

import useStyles from './styles';

interface IProps {
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  iconChecked?: ReactElement;
  iconUnChecked?: ReactElement;
  iconIndeterminate?: ReactElement;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
  size?: 'small' | 'medium';
  checked?: boolean | null;
  customColorChecked?: string;
  customColorUnChecked?: string;
  className?: string;
  onChange?: () => void;
}

const CheckBox: React.FC<IProps> = props => {
  const {
    iconChecked,
    iconUnChecked,
    label,
    labelPlacement,
    checked,
    iconIndeterminate,
    size,
    color,
    customColorChecked,
    customColorUnChecked,
    className,
    onChange
  } = props;

  const c = useStyles();

  const sx = {
    color: customColorUnChecked,
    '&.Mui-checked': {
      color: customColorChecked,
    },
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={onChange}
          indeterminate={!!(checked === null)}
          disableRipple={true}
          icon={iconChecked}
          checkedIcon={iconUnChecked}
          indeterminateIcon={iconIndeterminate}
          checked={checked!}
          size={size!}
          color={color!}
          sx={sx}
        />
      }
      label={label ? label : ''}
      labelPlacement={labelPlacement!}
    />
  );
};

export default CheckBox;
