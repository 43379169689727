import { makeStyles } from '@mui/styles';
import { DefaultTheme } from '@mui/styles';

import { bgColors, borderRadius, fontColors } from 'themes/materialTheme';
import { IColorDef } from 'themes/types/interfaces';

interface IProps {
  color?: IColorDef;
}

const useStyles = makeStyles<DefaultTheme, IProps>(theme => ({
  smButtonClass: {
    height: '32px',
    minWidth: '64px',
  },
  mdButtonClass: {
    height: '40px',
    minWidth: '96px',
  },
  lgButtonClass: {
    height: '48px',
    minWidth: '120px',
  },
  primaryButton: props => ({
    borderRadius: borderRadius.medium,
    backgroundColor: props.color?.medium || bgColors.mediumBlue,
    '&:hover': {
      backgroundColor: props.color?.dark || bgColors.darkBlue,
    },
  }),
  disabledButtonCaption: {
    color: fontColors.mediumGrey,
  },
  primaryCaption: {
    color: fontColors.white,
  },
  focusedButton: {
    boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.05)`,
  },
}));

export default useStyles;
