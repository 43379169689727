import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  desc: {
    flex: 1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(6),
  },
}));

export default useStyles;
