import React from 'react';
import clsx from 'clsx';

import ExamplesWrapper from 'Modules/MainPage/Components/ExamplesWrapper';

import { UserForm } from './Components/UserForm';

import usersStore from 'Stores/usersStore';

import useStyles from './styles';

interface IProps {
  className?: string;
}

export enum FormTypes {
  First = 'Вариант 1',
  Second = 'Вариант 2',
}

const Forms = (props: IProps) => {
  const c = useStyles();

  const { className } = props;

  const items = [FormTypes.First];

  const dropToDefault = () => {
    usersStore.user.clear();
  };

  const userFrom = (
    <>
      <UserForm />

    </>
  );

  return (
    <ExamplesWrapper
      dropToDefault={dropToDefault}
      render={param => userFrom}
      title={'Пример формы'}
      description={''}
      examples={items}
    />
  );
};

export default Forms;
