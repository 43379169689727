import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import useStyles from './styles';

interface IProps {
  size?: SizeSpinner;
  isLoading: boolean;
  zIndex?: number;
}

export enum SizeSpinner {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}

const Spinner = (props: IProps) => {
  const { isLoading, size, zIndex } = props;

  const c = useStyles({ size, zIndex });

  return isLoading ? <div className={c.spinner}>{<CircularProgress />}</div> : null;
};

export default Spinner;
