import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  rowWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputWrapper: {
    width: '250px',
    margin: '16px',
  },
  successIcon: {
    color: 'green',
  },
}));

export default useStyles;
