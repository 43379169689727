import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { ICardItem } from '../../index';

import useStyles from './styles';

interface IProps {
  items: ICardItem[];
  className?: string;
}

const TableView = (props: IProps) => {
  const { items, className } = props;

  const c = useStyles();

  const [observableData, setObservableData] = useState(items);

  useEffect(() => {
    setObservableData(items);
  }, [items]);

  return (
    <div className={clsx(c.items, className || '')}>
      <div className={c.titles}>
        {observableData.map(item => (
          <span key={item.text} className={c.title}>
            {item.text}:
          </span>
        ))}
      </div>

      <div className={c.values}>
        {observableData.map(item => (
          <span key={item.text} className={c.text}>
            {item.value}
          </span>
        ))}
      </div>
    </div>
  );
};

export default observer(TableView);
