import React from 'react';

import clsx from 'clsx';

import { Button } from '@mui/material';

import { TextBtn } from 'Elements/TextWrappers';
import { Spinner } from 'Elements/GropuingGroup/Spinner';

import { SizeSpinner } from 'Elements/GropuingGroup/Spinner/Spinner';
import { ButtonSize, VariantButton } from './types';

import { IColorDef } from 'themes/types/interfaces';

import useStyles from './styles';

interface IProps {
  buttonClassName?: string;
  captionClassName?: string;
  color?: IColorDef;
  caption?: string;
  captionRender?: JSX.Element;
  disabled?: boolean;
  disableRipple?: boolean;
  endIcon?: JSX.Element;
  isPending?: boolean;
  startIcon?: JSX.Element;
  size?: ButtonSize;

  onClick(): void;
}

const SecondaryButton = (props: IProps) => {
  const {
    buttonClassName,
    captionClassName,
    caption,
    captionRender,
    color,
    disabled,
    disableRipple,
    endIcon,
    isPending,
    startIcon,
    size,
    onClick,
  } = props;

  const c = useStyles({ color });

  let classNameBySize = c.mdButtonClass;
  let captionTextSize = null;

  switch (size) {
    case ButtonSize.Sm: {
      classNameBySize = c.smButtonClass;
      captionTextSize = 14;
      break;
    }

    case ButtonSize.Md: {
      classNameBySize = c.mdButtonClass;
      break;
    }

    case ButtonSize.Lg: {
      classNameBySize = c.lgButtonClass;
      captionTextSize = 20;
      break;
    }
  }

  const defaultCaption = (
    <>
      <Spinner size={SizeSpinner.Md} isLoading={isPending || false} />
      <TextBtn
        color={color?.medium! || ''}
        size={captionTextSize}
        className={captionClassName || disabled ? c.disabledButtonCaption : c.secondaryCaption}
        text={caption}
      />
    </>
  );

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      focusVisibleClassName={c.focusedButton}
      className={buttonClassName ? classNameBySize : clsx(classNameBySize, c.secondaryButton)}
      disabled={disabled}
      disableRipple={!disableRipple}
      variant={VariantButton.Outlined}
      onClick={onClick}>
      {captionRender ? captionRender : defaultCaption}
    </Button>
  );
};

export default SecondaryButton;
