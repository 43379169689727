import { makeStyles } from '@mui/styles';
import { borderColors, borderRadius } from '../../../themes/materialTheme';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  selectWrapper: {
    textAlign: 'left',
    height: '32px',
    width: '100%',

    '& fieldset': {
      borderColor: borderColors.lightBlue,
      borderRadius: borderRadius.medium,
    },
    '&:hover fieldset': {
      borderColor: borderColors.darkBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: borderColors.mediumBlue,
    },
  },
  rowItem: {
    display: 'flex',
    alignItems: 'center',
  },
  itemIcon: {
    marginRight: '16px',
  },
});

export default useStyles;
