import { makeStyles } from '@mui/styles';

import { indents } from 'themes/materialTheme';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    width: '250px',
    margin: '16px',
  },
  leftSizeExample: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputLong: {
    width: '200px',
  },
  inputWrapperLong: {
    marginTop: theme.spacing(indents.thirtyTwo),
  },
}));

export default useStyles;
