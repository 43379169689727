import React from 'react';

import { ITextWrapperProps } from '../types';

import useStyles from './styles';

const TextC = (props: ITextWrapperProps) => {
  const { className, children, color, id, ref, fontWeight, noWrap, text } = props;
  const c = useStyles({ fontWeight, color, noWrap });

  return (
    <span className={className ? `${className} ${c.textCaption}` : c.textCaption} id={id} ref={ref}>
      {children ? children : text}
    </span>
  );
};

export default TextC;
