import React from 'react';
import { observer } from 'mobx-react';

import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

import { InputWrapper } from 'Elements/GropuingGroup';
import { TextC } from 'Elements/TextWrappers';
import { TextInput, TextInputSize } from 'Elements/InputsGroup/TextInput';
import { Select } from 'Elements/InputsGroup/Select';
import { IconButton, IconButtonSize } from 'Elements/Buttons';

import { appStore } from 'Stores';

import useStyles from './styles';

const DisplayControl = () => {
  const c = useStyles();

  return (
    <div className={c.displayControl}>
      <IconButton
        className={c.displayControlItem}
        size={IconButtonSize.Large}
        hint={'Change orientation'}
        icon={<ScreenRotationIcon fontSize={IconButtonSize.Small} />}
        onClick={appStore.fullScreenParam.rotateDisplay}
      />

      <InputWrapper
        className={c.displayControlItem}
        labelCell={<TextC text={'screen size:'} />}
        spaceBetweenSize={8}
        inputCell={
          <Select
            className={c.displaySizeSelect}
            onChange={appStore.fullScreenParam.onSetResolution}
            items={appStore.fullScreenParam.resolutions}
            value={appStore.fullScreenParam.resolution}
          />
        }
      />

      <InputWrapper
        className={c.displayControlItem}
        labelCell={<TextC text={'width:'} />}
        spaceBetweenSize={8}
        inputCell={
          <TextInput
            className={c.displaySizeInput}
            onChange={appStore.fullScreenParam.onChangeWidth}
            value={appStore.fullScreenParam.width}
          />
        }
      />

      <InputWrapper
        className={c.displayControlItem}
        labelCell={<TextC text={'height:'} />}
        spaceBetweenSize={8}
        inputCell={
          <TextInput
            className={c.displaySizeInput}
            onChange={appStore.fullScreenParam.onChangeHeight}
            value={appStore.fullScreenParam.height}
          />
        }
      />
    </div>
  );
};

export default observer(DisplayControl);
