import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  items: {
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  titles: {
    flex: 1,
  },
  values: {
    overflow: 'hidden',
    marginLeft: theme.spacing(3),
  },
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  text: {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 500,
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default useStyles;
