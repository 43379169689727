import { makeAutoObservable } from 'mobx';

export interface IUserPutDto {
  skills: string[];
  organizationId: string;
  login: string;
  firstName: string;
  surname: string;
  patronymic: string;
}

export interface IUserPostDto {
  id?: string;
  organizationId: string | null;
  skills: string[] | null;
  login: string;
  firstName: string;
  surname: string;
  patronymic: string;
  password: string;
}

interface IImage {
  file: Blob | null;
  preview: string;
  data: string;
}

export enum ValidationErrorTypes {
  MinLength = 'Минимальная длина поля - 2 символа',
  MaxLength = 'Максимальная длина поля',
  RequiredField = 'Это обязательное поле',
  NoError = '',
}

class UserFormModel {
  public id = '';

  public firstName = '';

  public patronymic = '';

  public surname = '';

  public option: string = '';

  public organizations: string[] = [];

  public image: IImage = { file: null, preview: '', data: '' };

  public login = '';

  public checked = false;

  public organizationId = '';

  public password = '';

  public skills: string[] = [];

  public isValidationActivated = false;

  public skillsValidState = '';

  public firstNameValidState = '';

  public surnameValidState = '';

  public loginNameValidState = '';

  public patronymicValidState = '';

  public constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public getPostDto(): IUserPostDto | null {
    if (this.isNotValid()) return null;

    return {
      organizationId: this.organizationId,
      skills: this.skills,
      login: this.login,
      firstName: this.firstName,
      surname: this.surname,
      patronymic: this.patronymic,
      password: this.password,
    };
  }

  public getPutDto(): IUserPutDto | null {
    if (this.isNotValid()) return null;

    return {
      organizationId: this.organizationId,
      skills: this.skills,
      firstName: this.firstName,
      patronymic: this.patronymic,
      surname: this.surname,
      login: this.login,
    };
  }

  public updateFromDto(dto: IUserPostDto): void {
    this.id = dto.id!;
    this.organizationId = dto.organizationId!;
    this.skills = dto.skills!;
    this.login = dto.login;
    this.firstName = dto.firstName;
    this.surname = dto.surname;
    this.patronymic = dto.patronymic;
    this.password = dto.password;
  }

  public clear(): boolean {
    this.isValidationActivated = false;

    this.firstNameValidState = '';
    this.surnameValidState = '';
    this.loginNameValidState = '';
    this.skillsValidState = '';
    this.patronymicValidState = '';

    this.id = '';
    this.firstName = '';
    this.patronymic = '';
    this.surname = '';
    this.login = '';
    this.organizationId = '';
    this.password = '';
    this.skills = [];

    return true;
  }

  public onChangeSurname(value: string) {
    this.surname = value;

    if (this.isValidationActivated) {
      this.surnameValidState = this.checkForValid(this.surname);
    }
  }

  public onChangeFirstName(value: string) {
    this.firstName = value;

    if (this.isValidationActivated) {
      this.firstNameValidState = this.checkForValid(this.firstName);
    }
  }

  public onChangePatronymic(value: string) {
    this.patronymic = value;

    if (this.isValidationActivated) {
      this.patronymicValidState = this.checkForValidNotRequired(this.patronymic, 38);
    }
  }

  public onChangeOrg(value: string) {
    this.organizationId = value;
  }

  public onChangeLogin(value: string) {
    this.login = value;

    if (this.isValidationActivated) {
      this.loginNameValidState = this.checkForValid(this.login);
    }
  }

  public onCheck = () => {
    this.checked = !this.checked;
  };

  public onChangePassword(value: string) {
    this.password = value;
  }

  public onChangeSkills(skillsIds: string[]) {
    this.skills = skillsIds;

    if (this.isValidationActivated) {
      this.skillsValidState =
        this.skills.length > 0 ? ValidationErrorTypes.NoError : ValidationErrorTypes.RequiredField;
    }
  }

  public onUploadImage(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files) return;

    const file = event.target.files[0];

    this.image.file = file;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      if (!reader.result) return;

      const result = reader.result as string;

      this.image.preview = result;
      this.image.data = result.split('base64,')[1];
    };
  }

  public onRemoveImage(): void {
    this.image.file = null;
    this.image.data = '';
    this.image.preview = '';
  }

  private isNotValid(): boolean {
    this.isValidationActivated = true;
    this.surnameValidState = this.checkForValid(this.surname);
    this.firstNameValidState = this.checkForValid(this.firstName);
    this.loginNameValidState = this.checkForValid(this.login);
    this.patronymicValidState = this.checkForValidNotRequired(this.patronymic, 38);
    this.skillsValidState = this.skills.length > 0 ? ValidationErrorTypes.NoError : ValidationErrorTypes.RequiredField;

    return (
      !!this.surnameValidState ||
      !!this.firstNameValidState ||
      !!this.loginNameValidState ||
      !!this.skillsValidState ||
      !!this.patronymicValidState
    );

    return false;
  }

  private checkForValidNotRequired(value: string, maxLengthValue: number): string {
    if (value.length > maxLengthValue) {
      return ValidationErrorTypes.MaxLength + ` ${maxLengthValue} ` + 'символов';
    }

    return ValidationErrorTypes.NoError;
  }

  private checkForValid(value: string, maxLength?: number) {
    const maxLengthValue = maxLength || 38;

    if (!value) {
      return ValidationErrorTypes.RequiredField;
    }

    if (value.length === 0) {
      return ValidationErrorTypes.RequiredField;
    }

    if (value.length < 2) {
      return ValidationErrorTypes.MinLength;
    }

    if (value.length > maxLengthValue) {
      return ValidationErrorTypes.MaxLength + ` ${maxLengthValue} ` + 'символов';
    }

    return ValidationErrorTypes.NoError;
  }
}

export default UserFormModel;
