import React from 'react';

import { SecondaryButton } from 'Elements/Buttons';
import { ButtonSize } from 'Elements/Buttons/PrimaryButton';

import useStyles from '../../styles';

const SecondaryButtons = () => {
  const c = useStyles();

  return (
    <div className={c.wrapper}>
      <div className={c.exampleWrapper}>
        <SecondaryButton size={ButtonSize.Sm} onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <SecondaryButton onClick={() => {}} caption={'Apply'} />
      </div>

      <div className={c.exampleWrapper}>
        <SecondaryButton size={ButtonSize.Lg} onClick={() => {}} caption={'Apply'} />
      </div>
    </div>
  );
};

export default SecondaryButtons;
